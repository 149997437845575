import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userService } from "../../services/user.service"
import { AppStore } from "../../App";
import GlobalLoader from "../../components/GlobalLoader";
import { ChartBarIcon, CheckIcon, DocumentCheckIcon, InformationCircleIcon, ListBulletIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { SITE_URL } from "../../resources/env";
import ImageZoom from "../../components/ImageZoom";
import useRole from "../../hooks/useRole";
import Pagination from "../../components/Errands/Pagination";
import { socket } from "../../services/socket.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UserDetails()
{

    const {store,dispatch} = useContext(AppStore)

    const [link,setLink] = useState(null)

    const [commission,setCommission] = useState()

    const [operation,setOperation] = useState('')
    const [opamount,setOpamount] = useState(0)

    const [wallet,setWallet] = useState(false)

    const [metrics,setMetrics] = useState({})

    const [transactions,setTransactions] = useState([])

    const [toggleModif,setToggleModif] = useState(false)

    const [targetedUser,setTargetedUser] = useState(null)

    const [pic,setPic] = useState(null)

    const [cardRecto,setCardRecto] = useState(null)
    const [cardVerso,setCardVerso] = useState(null)

    const [passport,setPassport] = useState(null)

    const { setRole } = useRole()

    const params = useParams()

    const navigate = useNavigate()

    const fetchUserInfos = async () => {

        let data = {
            id : params.id
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.getUser(data)
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                console.log(response.data.user)
                setCommission(response.data.user.commission ?? 0)
                setTransactions(response.data.transactions)
            }
            else{
                alert('Something went wrong while fetching user info')
            }

        })

    }

    const getUserMetrics = async () => {

        let data = {
            id : params.id
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.getUserMetrics(data)
        .then(response => {

            // alert(JSON.stringify(response))

            if(response)
            {

                if(response.status == 200)
                {
                    setMetrics(response.data)
                }
                else{
                    console.log('Something went wrong while fetching user metrics')
                }

            }
            else{
                console.log('Connexion error')
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        })

    }

    const handleTouch = (e) => {

        if(e.target == document.getElementById('wallet-body'))
        {
            setWallet(false)
        }
        
    }

    const handleOperation = (e) => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        const data = {
            user_id : params.id,
            amount : opamount,
            type : operation
        }

        userService.handleOperation(data)
        .then(response => {
            
            if(response)
            {

                setWallet(false)

                if(response.status == 200)
                {
                    fetchUserInfos()
                }
                else{
                    console.log('Something went wrong while fetching user metrics')
                }

            }

        })

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const togglePsCommission = async () => {

        let data = {
            user_id : params.id
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        userService.activatePersonalizedCommission(data)
        .then(response => {

            // alert(JSON.stringify(response))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
            }
            else{
                alert('Something went wrong')
            }

        })

    }

    const setZoom = (e,link) => {

        e.preventDefault()

        dispatch({type : 'SET_ZOOM',payload : true})

        setLink(link)

    }

    const updateUser = async e => {

        e.preventDefault()

        if(window.confirm('Voulez vous valider cette operation ?'))
        {

            let data = {
                user_id : params.id,
                c_name : targetedUser.c_name,
                phone : targetedUser.phone,
                email : targetedUser.email,
                id_card_recto : cardRecto,
                id_card_verso : cardVerso,
                pic : pic,
                passport_pic : passport
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            await userService.updateUser(data)
            .then(async response => {

                if(response)
                {

                    if(response.status == 200)
                    {

                        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                        toast.success('Informations mises a jour avec succes')
                        setToggleModif(false)
                        setTargetedUser(null)
                        await fetchUserInfos()

                    }

                }

            })

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    useEffect(() => {

        fetchUserInfos()
        
        getUserMetrics()

        localStorage.setItem('page','other')

    },[])

    return (

        <React.Fragment>

            {store.loading && <GlobalLoader/>}

            <ToastContainer/>

            {
                !store.loading &&

                <section className="w-full">

                    <article className="mb-5">

                        <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                            <ChartBarIcon className="w-6 inline-flex text-black"/>
                            <span className="font-bold pl-2 text-black">Metriques</span>
                        </header>

                        {
                            setRole(store.userDetails.infos.role) == 'conducteur' ?

                            <div className="grid grid-cols-4 gap-5 py-7">

                                <div className="shadow shadow-gray-500 p-4">

                                    <div className="font-extrabold">
                                        Nombre de courses
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.t_errand}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-green-800">

                                    <div className="font-extrabold">
                                        Courses terminees
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                    {metrics.completed_errands}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-red-800">

                                    <div className="font-extrabold">
                                        Courses annulees
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.canceled_errands}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-orange-500">

                                    <div className="font-extrabold">
                                        Chiffre d'affaire total (Depuis la creation du compte)
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl text-black">
                                        {metrics.turnover}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-blue-500">

                                    <div className="font-extrabold">
                                        Profit total (Depuis la creation du compte)
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.profit}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Commissions total (Depuis la creation du compte)
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.commissions}
                                    </div>

                                </div>

                                {/* Weekly */}

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Commissions hebdomadaire
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.weekly_commissions}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Profits hebdomadaire
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.weekly_profit}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Chiffre d'affaire hebdomadaire
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.weekly_turnover}
                                    </div>

                                </div>

                                {/* Mensuel */}

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Chiffre d'affaire mensuel
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.monthly_turnover}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Profit mensuel
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.monthly_profit}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-sky-800">

                                    <div className="font-extrabold">
                                        Commissions mensuel
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.monthly_commissions}
                                    </div>

                                </div>

                            </div>

                            : 

                            <div className="grid grid-cols-4 gap-5 py-7">

                                <div className="shadow shadow-gray-500 p-4">

                                    <div className="font-extrabold">
                                        Nombre de courses
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.t_errand}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-green-800">

                                    <div className="font-extrabold">
                                        Courses terminees
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                    {metrics.completed_errands}
                                    </div>

                                </div>

                                <div className="shadow shadow-gray-500 p-4 text-red-800">

                                    <div className="font-extrabold">
                                        Courses annulees
                                    </div>

                                    <div className="my-4 font-extrabold text-2xl">
                                        {metrics.canceled_errands}
                                    </div>

                                </div>

                            </div>

                        }

                        <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                            <InformationCircleIcon className="w-6 inline-flex text-black"/>
                            <span className="font-bold pl-2 text-black">Historique des courses</span>
                        </header>

                        {
                            setRole(store.userDetails.infos.role) == 'conducteur' ? 
                            <div className=''>

                                <table className="w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                        metrics.e_list?.length > 0 ? 

                                        metrics.e_list.map((errand,index) => (
                                            <tr key={errand.id} className={`${errand?.thedriver == null && "bg-red-200"}`}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {index + 1}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.theclient?.c_name}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.infos[0]?.total_price} {errand.infos[0]?.total_price && <span className="font-bold text-sm">FCFA</span>}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.is_cav ? 'Volee' : 'Normal'}</td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                                    
                                                    {
                                                        errand.settled == false && 
                                                        <a onClick={e => {

                                                            e.preventDefault()

                                                            // Send a request to the websocket server to settle the journey

                                                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                                                            socket.emit('settle_errand',{
                                                                journey : errand.id,
                                                                final_path : errand.final_path,
                                                                total_distance : errand.total_distance
                                                            })

                                                            setTimeout(() => window.location.reload(),2000)

                                                        }} href="" className="text-white bg-indigo-900 rounded-md font-bold text-xs p-2">
                                                        Terminer
                                                    </a>
                                                    }

                                                </td>
                                            </tr>
                                        ))

                                        :

                                        <tr className=''>Aucune course</tr>

                                    }
                                    </tbody>
                                </table>

                                <div className="flex items-center gap-10 mt-5">

                                    <header className="font-bold">Pagination</header>

                                    <Pagination data={store.errands.list}/>

                                </div>

                            </div>

                            : 

                            <div className=''>

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Conducteur</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {
                                    metrics.e_list?.length > 0 ? 

                                    metrics.e_list.map((errand,index) => (
                                        <tr key={errand.id} className={`${errand?.thedriver == null && "bg-red-200"}`}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {index + 1}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.thedriver?.c_name}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.infos[0]?.total_price} {errand.infos[0]?.total_price && <span className="font-bold text-sm">FCFA</span>}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.is_cav ? 'Volee' : 'Normal'}</td>
                                        </tr>
                                    ))

                                    :

                                    <tr className=''>Aucune course</tr>

                                }
                                </tbody>
                            </table>

                            <div className="flex items-center gap-10 mt-5">

                                <header className="font-bold">Pagination</header>

                                <Pagination data={store.errands.list}/>

                            </div>

                        </div> 

                        }

                        

                    </article>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <InformationCircleIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Historique des transactions de l'utilisateur</span>
                    </header>

                    <article className='mb-10'>

                        <div className=''>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Utilisateur</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {
                                transactions.length > 0 ? 

                                transactions.map((transaction,index) => (
                                    <tr key={transaction.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {index + 1}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.user?.c_name}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.trx_type.name}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.amount}</td>
                                    </tr>
                                ))

                                :

                                <tr className=''>Aucune transaction</tr>

                            }
                            </tbody>
                        </table>

                        </div>

                    </article>


                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center">
                        <InformationCircleIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Informations de l'utilisateur</span>
                    </header>

                    <div className="my-5">
                        <b>Nom complet : </b> <span>{store.userDetails.infos.c_name}</span>
                    </div>

                    <div className="my-5">
                        <b>Email : </b> <span>{store.userDetails.infos.email}</span>
                    </div>

                    <div className="my-5">
                        <b>Numero de telephone : </b> <span>+229 {store.userDetails.infos.phone}</span>
                    </div>

                    <div className="my-5">
                        <b>Commission : </b> <span> {store.userDetails.infos.commission ?? 0}%</span>
                    </div>

                    <div className="my-5">
                        <b>Solde : </b> <span> {store.userDetails?.infos?.wallet?.balance ?? 0} FCFA</span>
                    </div>

                    <div className="my-5 p-5 shadow shadow-gray-500 rounded-md inline-block">
                        <b>Nombre de filleuls : </b> <span> {store.userDetails?.infos?.children?.length}</span>
                    </div>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <ListBulletIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Liste des filleuls</span>
                    </header>

                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nom complet</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Numero de telephone</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            store.userDetails?.infos?.children?.length > 0 ? 

                            store.userDetails?.infos?.children?.map((child,index) => (
                                <tr key={child.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{child?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{child?.phone}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{child?.email}</td>
                                </tr>
                            ))

                            :

                            <tr className=''>Aucun filleul pour le moment</tr>

                        }
                        </tbody>
                    </table>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <DocumentCheckIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Documents</span>
                    </header>

                    <div className="my-5 grid grid-cols-2 gap-10">

                        {
                            store.userDetails.zoomActive && <ImageZoom link={link}/>
                        }

                        {/* {JSON.stringify(store.userDetails.infos)} */}
                        
                        {
                            store.userDetails.infos.id_card_recto != '' && 

                            <div className="">

                                <header className="font-bold text-lg my-5">Carte d'identite</header>

                                <div className="flex gap-5">

                                    <div className="relative">
                                        <img src={`${store.userDetails.infos.id_card_recto}`} className="w-full h-full"/>
                                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                            <a onClick={e => setZoom(e,`${store.userDetails.infos.id_card_recto}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                                <PlusCircleIcon className="w-5"/>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="relative">
                                        <img src={`${store.userDetails.infos.id_card_verso}`} className="w-full h-full"/>
                                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                            <a onClick={e => setZoom(e,`${store.userDetails.infos.id_card_verso}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                                <PlusCircleIcon className="w-5"/>
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        }

                        {
                            store.userDetails.infos.passport_pic != '' && 

                            <div className="">

                                <header className="font-bold text-lg my-5">Passport</header>

                                {/* <div className="">
                                    <img src={`${SITE_URL}/${store.userDetails.infos.passport_pic}`}/>
                                </div> */}

                                <div className="relative h-60 w-60">
                                    <img src={`${store.userDetails.infos.passport_pic}`} className="w-full h-full"/>
                                    <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                        <a onClick={e => setZoom(e,`${store.userDetails.infos.passport_pic}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                            <PlusCircleIcon className="w-5"/>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        }

                        {
                            store.userDetails.infos.pic != '' && 

                            <div className="">

                                <header className="font-bold text-lg my-5">Photo de profile</header>

                                {/* <div className="">
                                    <img src={`${SITE_URL}/${store.userDetails.infos.passport_pic}`}/>
                                </div> */}

                                <div className="relative h-60 w-60">
                                    <img src={`${store.userDetails.infos.pic}`} className="w-full h-full"/>
                                    <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                        <a onClick={e => setZoom(e,`${store.userDetails.infos.pic}`)}  className=" bg-blue-700 w-10 h-10 flex items-center justify-center cursor-pointer text-white rounded-full">
                                            <PlusCircleIcon className="w-5"/>
                                        </a>
                                    </div>
                                </div>

                            </div>

                        }

                    </div>

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center my-10">
                        <ListBulletIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Actions</span>
                    </header>

                    <div className="flex gap-3">

                        <form className="" onSubmit={e => {

                            e.preventDefault()

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.activatePersonalizedCommission({
                                user_id : params.id
                            })
                            .then(response => {

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // Dispatch the user details
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check internet connexion')
                                }

                            })

                        }}>

                            <div className="pb-4 flex items-center gap-2">

                                <button onClick={togglePsCommission} type="submit" className={`${store.userDetails.infos.personalized_commission_activated ? 'bg-red-700' : 'bg-green-700' } p-3 text-white rounded`}>
                                    {
                                        store.userDetails.infos.personalized_commission_activated ? 'Desactiver la commission personnalisee' : 'Activer la commission personnalisee'
                                    }
                                </button>
                                
                            </div>

                        </form>

                        <form className="" onSubmit={e => {

                            e.preventDefault()

                            let data = {
                                id : params.id
                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.lockUserAccount(data)
                            .then(response => {

                                // alert(JSON.stringify(response))

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // Dispatch the user details
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check internet connexion')
                                }

                            })

                        }}>

                            <button type="submit" className={`${store.userDetails.infos.account_locked ? 'bg-red-700' : 'bg-green-700' } p-3 text-white rounded`}>
                                {
                                    store.userDetails.infos.account_locked ? 'Debloquer le compte' : 'Bloquer le compte'
                                }
                            </button>

                        </form>

                        <form className="" onSubmit={e => {

                            e.preventDefault()

                            let data = {
                                user_id : params.id
                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            if(window.confirm('Voulez vous vraiment supprimer le compte ?'))
                            {

                                userService.deleteUserAccount(data)
                                .then(response => {

                                    // alert(JSON.stringify(response))

                                    dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                    if(response)
                                    {

                                        if(response.status == 200)
                                        {
                                            // Dispatch the user details
                                            navigate('/users')
                                            toast.success('Compte supprime avec succes')
                                        }

                                    }
                                    else{
                                        console.log('Check internet connexion')
                                    }

                                })

                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                        }}>

                        <button type="submit" className={`bg-red-700 p-3 text-white rounded`}>
                            Supprimer le compte
                        </button>

                        </form>

                        <div className="">
                            <button onClick={e => setWallet(!wallet)} className={`bg-sky-700 p-3 text-white rounded`}>Portefeuille</button>
                        </div>

                        {
                            wallet ?
                            <div onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center" id="wallet-body">

                                <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                                    <form className="" onSubmit={handleOperation}>

                                        <header className="font-bold text-lg">Crediter ou defalquer le portefeuille</header>

                                        <div className="pb-4">
                                            <label className="block py-4">Type d'operation</label>
                                            <select name="" onChange={(e) => setOperation(e.target.value)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none">
                                                <option value={``}>Type d'operation</option>
                                                <option value={`depot`}>Crediter</option>
                                                <option value={`retrait`}>Defalquer</option>                                                
                                            </select>
                                        </div>

                                        <div className="">
                                            <label className="block py-4">Montant<span className="font-bold text-xs text-red-700">(Uniquement pour la gestion de vehicules)</span></label>
                                            <input type="number" name="car_payment" onChange={e => setOpamount(e.target.value)} className="bg-[#dbdbdb] p-3 w-full focus:outline-none"  placeholder="Montant"/>
                                        </div>

                                        <div className="mt-4">
                                            <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Effectuer</button>
                                        </div>

                                    </form>

                                </article>

                            </div>: ''
                        }

                    </div>

                    {
                        store.userDetails.infos.personalized_commission_activated ?
                        <form className="w-8/12" onSubmit={e => {

                            e.preventDefault()

                            let data = {
                                user_id : params.id,
                                commission : commission
                            }

                            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

                            userService.setDriverCommission(data)
                            .then(response => {

                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                                // alert(JSON.stringify(response))
                                
                                if(response)
                                {

                                    if(response.status == 200)
                                    {
                                        // alert(JSON.stringify(response.data.user))
                                        dispatch({type : 'SET_USER_INFOS', payload : response.data.user})
                                    }

                                }
                                else{
                                    console.log('Check your internet connexion')
                                }

                            })

                        }}>
                            
                            <div className="">

                                <label className="block my-3">Definir la commission (%)</label>
                                <input className="border border-gray-400 rounded block w-full mt-2 p-1" value={commission} onChange={e => setCommission(e.target.value)}  type="text" placeholder=""/>

                            </div>

                            <div className="mt-5">
                                <button type="submit" className={`bg-blue-700 p-3 text-white rounded`}>Valider</button>
                            </div>

                        </form> :
                        ''
                    }

                    <div className="">
                        <button onClick={e => {
                            
                            setToggleModif(true)
                            setTargetedUser(store.userDetails.infos)

                        }} className={`bg-cyan-400 p-3 text-black rounded`}>Modifier les informations</button>
                    </div>

                    {
                        toggleModif ? 
                        <section onClick={e => {

                            e.preventDefault()

                            setToggleModif(false)
                            setTargetedUser(null)
                            

                        }} className="fixed bg-black/50 inset-0 flex items-center justify-center z-30">

                            <article onClick={e => e.stopPropagation()} className="bg-white w-7/12 h-[600px] overflow-y-auto p-10">

                                <form className="" onSubmit={e => updateUser(e)}>

                                    <header className="mb-5 font-extrabold text-lg">
                                        Informations textuelles
                                    </header>

                                    <div className="pb-4">
                                        <label className="block py-4">Nom complet <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        <input type="text" value={targetedUser.c_name} name="link" onChange={e => setTargetedUser({...targetedUser,c_name : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Nom du client`}/>
                                    </div>

                                    <div className="pb-4">
                                        <label className="block py-4">Email <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        <input type="text" value={targetedUser.email} name="link" onChange={e => setTargetedUser({...targetedUser,email : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Email du client`}/>
                                    </div>

                                    <div className="pb-4">
                                        <label className="block py-4">Numero de telephone <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        <input type="text" value={targetedUser.phone} name="link" onChange={e => setTargetedUser({...targetedUser,phone : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Numero de telephone`}/>
                                    </div>

                                    <header className="mb-5 font-extrabold text-lg">
                                        Images
                                    </header>

                                    <div className="pb-4">
                                        <label className="block py-1">Photo de profile <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        {
                                            targetedUser.pic != null ? 
                                            <label className="block py-2">
                                                <a href={targetedUser.pic} target="_blank" className="underline text-blue-600">Voir</a>
                                            </label>
                                            :
                                            <span className="italic text-sm text-blue-600 font-bold block mb-5">Aucune photo disponible pour le moment</span>
                                        }
                                        
                                        <input type="file" name="link" onChange={e => setPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                    </div>

                                    <div className="pb-4">
                                        <label className="block py-1">Recto de la carte d'identite <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        {
                                            targetedUser.id_card_recto != null ? 
                                            <label className="block py-2">
                                                <a href={targetedUser.id_card_recto} target="_blank" className="underline text-blue-600">Voir</a>
                                            </label>
                                            :
                                            <span className="italic text-sm text-blue-600 font-bold block mb-5">Aucune photo disponible pour le moment</span>
                                        }
                                        
                                        <input type="file" name="link" onChange={e => setCardRecto(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                    </div>

                                    <div className="pb-4">
                                        <label className="block py-1">Verso de la carte d'identite <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        {
                                            targetedUser.id_card_verso != null ? 
                                            <label className="block py-2">
                                                <a href={targetedUser.id_card_verso} target="_blank" className="underline text-blue-600">Voir</a>
                                            </label>
                                            :
                                            <span className="italic text-sm text-blue-600 font-bold block mb-5">Aucune photo disponible pour le moment</span>
                                        }
                                        
                                        <input type="file" name="link" onChange={e => setCardVerso(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                    </div>

                                    <div className="pb-4">
                                        <label className="block py-1">Photo du passport <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                        {
                                            targetedUser.passport_pic != null ? 
                                            <label className="block py-2">
                                                <a href={targetedUser.passport_pic} target="_blank" className="underline text-blue-600">Voir</a>
                                            </label>
                                            :
                                            <span className="italic text-sm text-blue-600 font-bold block mb-5">Aucune photo disponible pour le moment</span>
                                        }
                                        
                                        <input type="file" name="link" onChange={e => setPassport(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                    </div>

                                    <div>
                                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Mettre a jour</button>
                                    </div>

                                </form>

                            </article>

                        </section>
                        :
                        ''
                    }

                </section>

            }

        </React.Fragment>

    )

}