import { ListBulletIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import CreateCatery from "../../components/Categories/CreateCategory"
import { useContext, useEffect } from "react";
import { AppStore } from "../../App";
import Spinner from "../../components/Spinner";
import categories from "../../services/categories.service";
import UpdateCategory from "../../components/Categories/UpdateCategory";
import CreateCategory from "../../components/Categories/CreateCategory";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../../components/GlobalLoader";

export default function Categories()
{

    const {store,dispatch} = useContext(AppStore)

    async function fetchCategories() {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        categories.getCategories()
        .then(response => {

            console.log(JSON.stringify(response.status))

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response.status == 200)
            {
                dispatch({type : 'SET_CATEGORIES',payload : response.data.categories})
                console.log('Action dispatched')
            }
            else{
                // alert('Error while fetching the locomotion categories')
            }

        })

    }

    async function callCategoryUploadInterface(e,category)
    {

        e.preventDefault()

        // Set category to update

        // Then show the interface
        
        dispatch({type : 'SET_CATEGORY_TO_UPDATE',payload : category})
        dispatch({type : 'TOGGLE_CATEGORY_UPDATE_INTERFACE',payload : true})

    }

    async function deleteCategory(e,category)
    {

        e.preventDefault()

        if(window.confirm('Cette action est irreversible. Voulez vous poursuivre ?'))
        {

            let data = {
                id : category.id
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            categories.deleteCategory(data)
            .then(response => {

                // alert(JSON.stringify(response))

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                if(response.status == 200)
                {
                    dispatch({type : 'SET_CATEGORIES',payload : response.data.categories})
                    toast.success('Categorie supprimee avec succes')
                }
                else{
                    console.log(response)
                }

            })

        }

    }

    useEffect(() => {

        fetchCategories()
        localStorage.setItem('page','other')

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                <div className="">
                    <ListBulletIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Annonces</span>
                </div>
                <div className="">
                    <button onClick={e => dispatch({type : 'TOGGLE_CATEGORY_CREATION_INTERFACE',payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div>
                {
                    store.categories.creationInterfaceVisible && <CreateCategory/>
                }
                {
                    store.categories.updateInterfaceVisible && <UpdateCategory/>
                }
            </header>

            {
                store.loading && <GlobalLoader/>
            }

            <section className="">

                    {
                        store.categories.list?.length == 0 && 
                        <div className="">
                            Aucune categorie pour le moment
                        </div>
                    }

                    {
                        store.categories.list?.length > 0 && 
                        <div className="">
                            
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Nom
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Distance minimum
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix minimum
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix de base
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix au km
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix a la minute
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {store.categories.list?.map((category,index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {category.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{category.base_distance}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{category.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{category.base_price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{category.kilometer_price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{category.minute_pricing}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex items-center gap-2">
                                        <a href="" onClick={e => callCategoryUploadInterface(e,category)}>
                                            <PencilIcon className="w-5 text-blue-700"/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        </div>
                    }

                </section>


            
        </section>

    )

}