import { getData, postData } from "../lib/request";
import { DELETE_TRANSACTION, GET_TRANSACTIONS_HISTORY, TRX_PASSWORD_CHECK } from "../resources/routes";


export default class TrxService {

    static async checkPassword(data)
    {

        let response = postData(data,TRX_PASSWORD_CHECK)

        return response

    }


    static async getHistory()
    {

        let response = await getData(GET_TRANSACTIONS_HISTORY)

        return response
    }

    static async deleteTransaction(data)
    {

        let response = postData(data,DELETE_TRANSACTION)

        return response

    }

}