import { CheckBadgeIcon, MapIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useRef, useState } from "react";
import { AppStore } from "../App";
import house from "../services/house.service";
import { toast } from "react-toastify";
import GlobalLoader from "./GlobalLoader";

export default function HouseActivation()
{

    const {store,dispatch} = useContext(AppStore)

    const callExecuted = useRef(false)

    const [flats,setFlats] = useState([])

    const [rps,setRps] = useState([])

    const getUnactivatedFurnishedFlats = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.getUnactivatedFurnishedFlats()

        // Set the unactivated furnished flats in the application

        if(response)
        {

            if(response.status == 200)
            {
                setFlats(response.data.flats)
            }

        }

        // Switch of the loader

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const activateFlat = async (e,flat) => {

        e.preventDefault()

        if(window.confirm('Voulez vous poursuivre ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let response = await house.activateFlat({
                id : flat.id
            })

            if(response)
            {
                if(response.status)
                {
                    toast.success('Appartement active avec succes')
                    setTimeout(() => window.location.reload(),1500)
                }
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const getUnactivatedGuesthouses = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.getUnactivatedGuesthouses()

        // Set the unactivated furnished flats in the application

        if(response)
        {

            if(response.status == 200)
            {
                console.log(response.data)
                setRps(response.data.guesthouses)
            }
            else{
                console.log('Error getting the unactivated guest houses')
            }

        }

        // Switch of the loader

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }


    const activateGuesthouse = async (e,flat) => {

        e.preventDefault()

        if(window.confirm('Voulez vous poursuivre ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let response = await house.activateGuesthouse({
                id : flat.id
            })
    
            if(response)
            {
                if(response.status)
                {
                    toast.success('Appartement active avec succes')
                    setTimeout(() => window.location.reload(),1500)
                }
            }
    
            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    useEffect(() => {

        if(callExecuted.current == false)
        {

            getUnactivatedFurnishedFlats()

            getUnactivatedGuesthouses()

            localStorage.setItem('page','other')

            callExecuted.current = true

        }

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg">
                <MapIcon className="w-6 inline-flex text-black"/>
                <span className="font-bold pl-2 text-black">Maisons en attente d'activation</span>
            </header>

            {store.loading && <GlobalLoader/>}
            
            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Appartements en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix journalier</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        flats.length > 0 ? 

                        flats.map((flat,index) => (
                            <tr key={flat.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.prop?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.prop?.tel}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.daily_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <a onClick={e => activateFlat(e,flat)} href="" className="text-indigo-600 hover:text-indigo-900">
                                        <CheckBadgeIcon className='w-6'/>
                                    </a>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucun appartement en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>

            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Guesthouses en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix journalier</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        rps.length > 0 ? 

                        rps.map((gh,index) => (
                            <tr key={gh.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.prop?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.prop?.phone}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.daily_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{gh.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <a onClick={e => activateGuesthouse(e,gh)} href="" className="text-indigo-600 hover:text-indigo-900">
                                        <CheckBadgeIcon className='w-6'/>
                                    </a>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucune guest house en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>

        </section>

    )

}