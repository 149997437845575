import { getData, postData } from "../../lib/request";
import { BK_GET_LEASABLE_CARS, DELETE_LEASABLE_CAR, GET_LEASABLE_CARS, GET_PENDING_CARS, REMOVE_CAR_GALLERY_IMAGE, SET_PENDING_CARS, UPDATE_CAR } from "../../resources/routes";
import { FORM_DATA } from "../../resources/types";


export default class CarService
{

    static async getPendingCars()
    {

        let response = await getData(GET_PENDING_CARS)

        return response

    }

    static async setPendingCar(data)
    {

        let response = await postData(data,SET_PENDING_CARS)

        return response

    }

    static async getLeasableCars()
    {
        

        let response = await getData(BK_GET_LEASABLE_CARS)

        return response

    }

    static async deleteLeasableCar(data)
    {

        let response = await postData(data,DELETE_LEASABLE_CAR)

        return response

    }

    static async removeImage(data)
    {

        let response = await postData(data,REMOVE_CAR_GALLERY_IMAGE)

        return response

    }

    static async updateCar(data)
    {

        let response = await postData(data,UPDATE_CAR,FORM_DATA)

        return response

    }

}