import React from "react";
import { getData, postData } from "../lib/request";
import { ACTIVATE_PERSONALIZED_COMMISSION, ADD_NEW_ADMIN, BROADCAST_MESSAGES, CLOSE_SUBSCRIPTION, DELETE_ADMIN, DELETE_USER_ACCOUNT, FILTER_USER_BY_ROLE, GET_ADMINS, GET_ONLINE_USERS, GET_ROLES, GET_USER, GET_USER_METRICS, LOCK_USER_ACCOUNT, SET_DRIVER_COMMISSION, UPDATE_ADMIN, UPDATE_USER, WALLET_OP } from "../resources/routes";
import { FORM_DATA } from "../resources/types";


export class userService {


    static async getUser(data)
    {

        let response = await getData(GET_USER + '/' + data.id)

        return response

    }

    static async filterByRole(data)
    {

        let response = await postData(data,FILTER_USER_BY_ROLE)

        console.log(response)

        return response

    }

    static async getRoles()
    {

        let response = await getData(GET_ROLES)

        return response

    }

    static async activatePersonalizedCommission(data)
    {

        let response = await postData(data,ACTIVATE_PERSONALIZED_COMMISSION)

        return response

    }

    static async lockUserAccount(data)
    {

        let response = await postData(data,LOCK_USER_ACCOUNT)

        return response

    }

    static async broadcastMessage(data)
    {

        let response = await postData(data,BROADCAST_MESSAGES)

        return response

    }

    static async getOnlineUsers(data)
    {

        let response = await postData(data,GET_ONLINE_USERS)

        return response

    }

    static async setDriverCommission(data)
    {

        let response = await postData(data,SET_DRIVER_COMMISSION + '/' + data.user_id)

        return response

    }

    static async getUserMetrics(data)
    {

        let response = await getData(GET_USER_METRICS + '/' + data.id)

        return response

    }

    static async deleteUserAccount(data)
    {

        let response = await postData(data,DELETE_USER_ACCOUNT)

        return response

    }

    static async handleOperation(data)
    {

        let response = await postData(data,WALLET_OP)

        return response

    }

    static async closeSubscription (data)
    {

        let response = await postData(data,CLOSE_SUBSCRIPTION)

        return response

    }

    // Update user

    static async updateUser(data)
    {

        let response = await postData(data,UPDATE_USER,FORM_DATA)

        return response

    }

    static async getAdmins()
    {

        let response = await getData(GET_ADMINS)

        return response

    }

    static async addAdmin(data)
    {

        let response = await postData(data,ADD_NEW_ADMIN)

        return response

    }

    static async updateAdmin(data)
    {

        let response = await postData(data,UPDATE_ADMIN)

        return response

    }

    static async deleteAdmin(data)
    {

        let response = await postData(data,DELETE_ADMIN)

        return response

    }

}