import { useEffect, useState } from "react"
import GlobalLoader from "../../components/GlobalLoader"
import { BellAlertIcon, TrashIcon } from "@heroicons/react/24/outline"
import house from "../../services/house.service"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function SaleNotification()
{
    
    const [loading,setLoading] = useState(false)

    const [data,setData] = useState([])

    const getInterestedUsers = async () => {

        setLoading(true)

        let response = await house.getInterestedUsers()

        if(response)
        {
            
            if(response.status == 200)
            {
                console.log(response.data)
                setData(response.data)
            }
            
        }

        setLoading(false)

    }

    const handleRequest = async (e,type,id) => {

        setLoading(true)

        e.preventDefault()

        let data = {
            id : id,
            type : type
        }

        if(window.confirm('Voulez vous vraiment effectuer cette operation ?'))
        {

            let response = await house.handleRequest(data)

            if(response)
            {

                if(response.status == 200)
                {

                    toast.success('Operation effectuee avec succes')

                    setTimeout(() => window.location.reload(), 1200);
                    
                }

            }

        }

        setLoading(false)

    }

    useEffect(() => {
        getInterestedUsers()
        localStorage.setItem('page','other')
    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BellAlertIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Notification relatives aux ventes</span>
                </div>
            </header>

            <ToastContainer/>

            {loading && <GlobalLoader/>}

            {
                !loading && 

                <div className="">

                    <div className="">

                        {/* Houses */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Maisons</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {data.houses?.map((house,index) => (
                                <tr key={house.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house?.sellable?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {house.sellable?.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house?.user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {house.user?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house?.sellable.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            house?.sellable?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => handleRequest(e,"house",house.id)} className="bg-red-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <TrashIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    {/* Lands */}

                    <div className="my-20">

                        {/* Lands */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Terrains</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {data.lands?.map((land,index) => (
                                <tr key={land.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{land?.land?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {land.land?.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{land?.user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {land.user?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{land?.land.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            land?.land?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => handleRequest(e,"land",land.id)} className="bg-red-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <TrashIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    {/* Articles */}

                    <div className="my-20">

                        {/* Articles */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Articles</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero client
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {data.articles?.map((article,index) => (
                                <tr key={article.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{article?.article?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {article.article?.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{article?.user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {article.user?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{article?.article.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            article?.article?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => handleRequest(e,"article",article.id)} className="bg-red-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <TrashIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            }

        </section>
        
    )
}