import React, { createContext } from "react";
import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "../pages/dashboard";
import Login from "../pages/login";
import { AuthContext } from "../services/AuthProvider.service";
import Pubs from "../pages/dashboard/pubs";
import Users from "../pages/dashboard/users";
import Errands from "../pages/dashboard/errands";
import ErrandDetails from "../pages/dashboard/errand_details";
import Commandes from "../pages/dashboard/commandes";
import Emploi from "../pages/dashboard/emploi";
import Transactions from "../pages/dashboard/transactions";
import Settings from "../pages/dashboard/settings";
import Reservations from "../pages/dashboard/reservations";
import Products from "../pages/dashboard/products";
import ProductSubscriptions from "../pages/dashboard/subscriptions";
import Stats from "../pages/dashboard/stats";
import Promo from "../pages/dashboard/promo";
import UserDetails from "../pages/dashboard/userDetails";
import Categories from "../pages/dashboard/categories";
import Taxes from "../pages/dashboard/taxes";
import PendingCars from "../pages/dashboard/pending_cars";
import Notification from "../pages/dashboard/notification";
import LiveUsers from "../pages/dashboard/live";
import Houses from "../pages/dashboard/houses";
import History from "../pages/dashboard/hsitory";
import LeasableCars from "../pages/dashboard/leasable_cars";
import SaleActivation from "../pages/dashboard/sale_activation";
import SaleNotification from "../pages/dashboard/sale_notification";
import ImmoManagement from "../pages/dashboard/ImmoManagement";
import AdminMan from "../pages/dashboard/admin_man";

export default function Navigator({ctx_memo})
{

    return (

        <AuthContext.Provider value={ctx_memo}>

            <BrowserRouter>

                <Routes>

                    <Route path="/" element={<Layout ctx_memo={ctx_memo}/>}>

                        <Route index element={<Home/>}/>

                        <Route path="/pubs" element={<Pubs/>}/>

                        <Route path="/users" element={<Users ctx_memo={ctx_memo}/>}/>

                        <Route path="/user/:id" element={<UserDetails/>}/>

                        <Route path="/courses" element={<Errands/>}/>

                        <Route path="/courses/:id" element={<ErrandDetails/>}/>

                        <Route path="/commandes" element={<Commandes/>}/>

                        <Route path="/emploi" element={<Emploi/>}/>
                        
                        <Route path="/categories" element={<Categories/>}/>

                        <Route path="/transactions" element={<Transactions/>}/>

                        <Route path="/paramètres" element={<Settings/>}/>

                        <Route path="/produits" element={<Products/>}/>

                        <Route path="/impots" element={<Taxes/>}/>

                        <Route path="/comptabilite" element={<Stats/>}/>

                        <Route path="/promotion" element={<Promo/>}/>

                        <Route path="/produit/:id/:type" element={<ProductSubscriptions/>}/>

                        <Route path="/réservations" element={<Reservations/>}/>

                        <Route path="/historique-des-transactions" element={<History/>}/>

                        <Route path="/pending-cars" element={<PendingCars/>}/>

                        <Route path="/notifications" element={<Notification/>}/>

                        <Route path="/live-users" element={<LiveUsers/>}/>

                        <Route path="/leasable-cars" element={<LeasableCars/>}/>

                        <Route path="/sale-activation" element={<SaleActivation/>}/>

                        <Route path="/sale-notification" element={<SaleNotification/>}/>

                        <Route path="/maisons-en-attente-d-activation" element={<Houses/>}/>

                        <Route path="/gestion-immo" element={<ImmoManagement/>}/>

                        <Route path="/gestion-admin" element={<AdminMan/>}/>

                    </Route>

                    <Route path="/login" element={<Login/>}></Route>

                </Routes>

            </BrowserRouter>

        </AuthContext.Provider>

    )

}