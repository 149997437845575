import React, { useContext, useState } from "react";

import {
    PencilIcon,
    StarIcon,
    TrashIcon
  } from '@heroicons/react/24/outline'

import GlobalLoader from "./GlobalLoader";
import { DELETE_PUB, GET_PUBS } from "../resources/routes";
import useRequest from "../hooks/useRequest";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppStore } from "../App";
import Pub from "../services/pub.service";

export default function PubMember({link,image,id,stared})
{

    const {store,dispatch} = useContext(AppStore)

    const [pid,setPid] = useState(id)

    const {postData,getData} = useRequest()

    const [loading,setLoading] = useState(false)

    const fetch_pubs = async () => {

        let response = await getData(GET_PUBS).then(res => {

            // if(res.status == 401)
            // {
            //     logout()
            // }

            if(res.status == 200)
            {
                // alert(JSON.stringify(res.data))
                dispatch({type : 'SET_PUBS', payload : res.data.pubs})
            }
            else{
                // alert
                toast.warning('Something went wrong')
            }

            dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : false})

        })

    }

    const starPub = async (type = null) => {

        const text = type == 'unstar' ? 'Voulez vous vraiment retirer cette pub des favoris ?' : 'Voulez vous vraiment ajouter cette pub aux favoris ?'

        let data = {
            pub_id : pid,
            type : type
        }

        if(window.confirm(text))
        {

            dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : true})

            let response = await Pub.starPub(data)

            if(response)
            {

                if(response.status == 200)
                {
                    toast.success(response.data.message)
                    await fetch_pubs()
                }
                else{
                    alert(JSON.stringify(response.data))
                    toast.error("Something went wrong")
                }

            }

            dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : false})

        }

    }

    const handleSubmit = async e => {

        e.preventDefault()

        if(window.confirm('Voulez vous vraiment supprimer cette pub ?'))
        {

            dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : true})

            let data = {
                pub_id : pid
            }
    
            let response = await postData(data,DELETE_PUB)
    
            if(response.status == 200)
            {
                await fetch_pubs()
                .then(() => {
                    dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : false})
                    toast.success(response.data.message)
                })
            }
            else{
                alert(JSON.stringify(response.data))
                dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : false})
                toast.error("Something went wrong")
            }

        }

    }

    return (

        <div className="relative">

            {
                store.pubsPage.indicators.loading && <GlobalLoader/>
            }

            <ToastContainer/>

            <a href={link} target="_blank">
                <img src={image} alt="Some image" className="w-full h-full"/>
            </a>
            <div className="absolute inset-0 right-0 h-full bg-black bg-opacity-50 p-4">

                <form onSubmit={handleSubmit}>
                    <button type="submit" className="bg-white bg-opacity-80 p-2 rounded">
                        <TrashIcon className="w-6"/>
                    </button>
                </form>

            </div>
        
            {
                stared ? 

                <div className=" bg-orange-600 text-white p-2 absolute top-4 right-4 font-bold rounded-md">
                    Vedette
                </div>

                : ''
            }

            <div className="absolute bottom-4 right-4 flex ite gap-2">

                {/* Define modif and staring action calls here */}

                <button className="bg-blue-600 text-white p-3 rounded-md flex items-center gap-2">

                    <PencilIcon className="w-6"/>
                    <span className="">Modifier</span>

                </button>

                {
                    !stared ? 

                    <button onClick={e => starPub('star')} className="bg-white p-3 rounded-md flex items-center gap-2">

                        <StarIcon className="w-6"/>
                        <span className="">Mettre en vedette</span>

                    </button>

                    : 
                    <button onClick={e => starPub('unstar')} className="bg-red-600 text-white p-3 rounded-md flex items-center gap-2">

                        <StarIcon className="w-6"/>
                        <span className="">Retirer des vedettes</span>

                    </button>
                }

            </div>
            
        </div>

    )

}