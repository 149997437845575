import { useContext } from "react"
import { AppStore } from "../App"


export default function ImageZoom({link})
{

    const {store,dispatch} = useContext(AppStore)

    const handleTouch = (e) => {

        if(e.target == document.getElementById('activation-body'))
        {
            dispatch({type : 'SET_ZOOM',payload : false})
        }

    }

    return (

        <section onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center" id="activation-body">

            <article className="lg:w-6/12 p-4 overflow-y-auto h-[500px]">
                <img src={link} className="block w-full max-h-full"/>
            </article>

        </section>

    )

}