import { useEffect, useRef, useState } from "react"
import GlobalLoader from "./GlobalLoader";
import { BellAlertIcon, CheckBadgeIcon, HomeIcon } from "@heroicons/react/24/outline"
import house from "../services/house.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function SellableActivation()
{

    const callExecuted = useRef(false)
    
    const [loading,setLoading] = useState(false)

    const [sellables,setSellables] = useState([])

    const [landDetail,setLandDetail] = useState(null)

    const [houseDetail,setHouseDetail] = useState(null)

    const [articleDetail,setArticleDetail] = useState(null)

    const [detailSurface,setDetailSurface] = useState([])

    const getUnactivatedSellables = async () => {

        setLoading(true)

        let response = await house.getUnactivatedSellables()

        if(response)
        {

            if(response.status == 200)
            {

                console.log(response.data)

                setSellables(response.data)
                
            }

        }

        setLoading(false)

    }

    const handleDetailDisplay = (type,data) => {

        switch (type) {

            case "land":
                
            break;

            case "house":
                
            break;

            case "article":
                
            break;

        }

    }

    const activateSellable = async (e,type,id) => {

        e.preventDefault()

        setLoading(true)

        if(window.confirm('Voulez vous vraiment effectuer cette operation ?'))
        {

            let data = {
                id : id,
                type : type
            }
    
            let response = await house.activateSellable(data)
    
            if(response)
            {
    
                if(response.status == 200)
                {
    
                    toast.success('Activation effectuee avec succes')
    
                    setTimeout(() => window.location.reload(), 1200);
    
                }
                else{
                    console.log('Something went wrong')
                }
    
            }

            setLoading(false)

        }

    }

    useEffect(() => {

        if(!callExecuted.current)
        {
            callExecuted.current = true
            localStorage.setItem('page','other')
            getUnactivatedSellables()
        }
        
    },[])

    return (

        <section className="">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BellAlertIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Activation vente</span>
                </div>
            </header>

            <ToastContainer/>

            {loading && <GlobalLoader/>}

            {
                !loading && 

                <div className="">

                    <div className="">

                        {/* Houses */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Maisons</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {sellables.houses?.map((house,index) => (
                                <tr key={house.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {house.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{house?.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            house?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => activateSellable(e,'house',house.id)} className="bg-blue-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <CheckBadgeIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <div className="my-14">

                        {/* Lands */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Terrains</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {sellables.lands?.map((land,index) => (
                                <tr key={land.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{land?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {land.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{land?.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            land?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => activateSellable(e,'land',land.id)} className="bg-blue-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <CheckBadgeIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                    <div className="">

                        {/* Articles */}

                        <header className="font-extrabold text-lg">
                            <div className="">
                                <span className="font-bold pl-2 text-black">Articles</span>
                            </div>
                        </header>

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Numero proprietaire
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Prix
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Images
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {sellables.articles?.map((article,index) => (
                                <tr key={article.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{article?.prop?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {article.prop?.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{article?.price}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {
                                            article?.gallery?.map((img,index) => (
                                                <div className="">
                                                    <a href={img.pic} target="_blank" className="text-blue-700 underline">{`image ${index + 1}`}</a>
                                                </div>
                                            ))
                                        }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-2">
                                        <button onClick={e => activateSellable(e,'article',article.id)} className="bg-blue-800 text-white font-semibold p-2 rounded-md flex items-center gap-2">
                                            <CheckBadgeIcon className="w-4"/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            }

        </section>

    )

}