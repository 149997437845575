import React, { useContext, useState } from "react";
import useRequest from "../hooks/useRequest";
import { CREATE_PUB } from "../resources/routes";
import GlobalLoader from "./GlobalLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GET_PUBS } from "../resources/routes";
import { FORM_DATA, TYPE_JSON } from "../resources/types";
import { AppStore } from "../App";

export default function AddPub({context,pub_state_ctx,ctx_memo})
{

    const {store,dispatch} = useContext(AppStore)

    const {login,logout,isLogin} = useContext(ctx_memo)

    const [ltype,setLtype] = useState(null)

    const {postData,getData} = useRequest()

    const [link,setLink] = useState(null)

    const [pic,setPic] = useState()

    const handleTouch = e => {

        if(e.target == document.getElementById('add-view'))
        {
            dispatch({type : 'TOGGLE_PUB_PAGE_ADD_VIEW',payload : false})
        }

    }

    const fetch_pubs = async () => {

        let response = await getData(GET_PUBS).then(res => {

            if(res.status == 401)
            {
                logout()
            }

            if(res.status == 200)
            {
                // alert(JSON.stringify(res.data))
                dispatch({type : 'SET_PUBS', payload : res.data.pubs})
            }
            else{
                // alert
                toast.warning('Something went wrong')
            }

            dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : false})

        })

    }

    // const convert_to_base64 = async (file) => {

    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
      
    //       reader.onloadend = () => {
    //         // The result will be the base64-encoded string
    //         // setSrc(reader.result)
    //         resolve(reader.result);
    //       };
      
    //       reader.onerror = () => {
    //         reject(new Error('Error reading file as base64'));
    //       };
      
    //       // Read the file as a data URL (base64)
    //       reader.readAsDataURL(file);
    //     });

    // }

    // const handleFileChange = async e => {

    //     let file_to_base64 = await convert_to_base64(e.target.files[0])

    //     setPic({file:file_to_base64,extension:e.target.files[0].name.split('.').pop()})

    // }

    const handleSubmit = async e => {

        e.preventDefault()

        // alert(pic)

        let flink = null

        switch (ltype) {

            case 'tel':
                flink = 'tel:' + link
            break;

            case 'email':
                flink = 'mailto:' + link
            break;

            case 'link':
                flink = link
            break;

        }

        var data = {
            link : flink,
            pic : pic
        }

        dispatch({type : 'TOGGLE_PUB_PAGE_LOADING', payload : true})

        let response = await postData(data,CREATE_PUB,FORM_DATA)

        if(response.status == 200)
        {

            toast.success('Publicité ajoutée avec succès')

            await fetch_pubs()

            dispatch({type : 'TOGGLE_PUB_PAGE_ADD_VIEW',payload : false})

        }else{
            alert(JSON.stringify(response))
            toast.warning('Something went wrong')
        }

    }

    const matchType = type => {

        let obj = {}

        switch (type) {

            case 'link':
                obj.label = 'Lien simple'
                obj.placeholder = 'Ex : http://google.com'
                return obj
            break;

            case 'email':
                obj.label = 'Adresse email'
                obj.placeholder = 'Ex : toto@domain.com'
                return obj
            break;

            case 'tel':
                obj.label = 'Numero de telephone'
                obj.placeholder = 'Ex : 56789876'
                return obj
            break;

            default:
                obj.label = ''
                obj.placeholder = ''
                return obj

        }

    }

    

    return (

        <section id="add-view" onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <ToastContainer/>

                {store.pubsPage.indicators.loading && <GlobalLoader/>}

                {/* <img src={src} alt="dd"/> */}

                <form onSubmit={handleSubmit}>

                    <div className="pb-4">
                        <label className="block py-4">Type de lien</label>
                        <select required name="" onChange={e => setLtype(e.target.value)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none">
                            <option></option>
                            <option value='link'>Lien simple</option>
                            <option value='email'>Adresse email</option>
                            <option value='tel'>Numero de telephone</option>
                        </select>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4">{matchType(ltype).label}</label>
                        <input type="text" name="link" onChange={e => setLink(e.target.value)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={matchType(ltype).placeholder}/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4">Image de l'engin</label>
                        <input type="file" name="pic" onChange={e => setPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Valider</button>
                    </div>

                </form>

            </article>

        </section>

    )

}