import { EyeIcon, WifiIcon } from "@heroicons/react/24/outline";
import GlobalLoader from "../../components/GlobalLoader";
import { useContext, useEffect, useRef, useState } from "react";
import { AppStore } from "../../App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from "../../services/socket.service";
import { userService } from "../../services/user.service";
import { Link } from "react-router-dom";

export default function LiveUsers()
{

    const {store,dispatch} = useContext(AppStore)

    const [users,setUsers] = useState([])
    const [offlines,setOfflines] = useState([])

    const callExecuted = useRef(false)

    const getOnlineUsers = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        socket.connect()

        console.log('Is socket connected ? ' + socket.connected)

        socket.emit('request_online_users',{})

        socket.on('get_online_users',async data => {

            console.log('Event received')

            let users = JSON.parse(data.users)

            let ids = users.map(user => user.id)

            let _data = {
                ids : ids
            }

            let response = await userService.getOnlineUsers(_data)

            if(response.status == 200)
            {
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                console.log(response.data.users)

                setUsers(response.data.users)
                setOfflines(response.data.offline_users)

            }
            else{
                console.log(response)
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        })

    }

    useEffect(() => {

        if(!callExecuted.current)
        {
            
            getOnlineUsers()
            localStorage.setItem('page','other')

            callExecuted.current = true

        }

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                <div className="">
                    <WifiIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Utilisateurs connectes</span>
                </div>
            </header>

            {store.loading && <GlobalLoader/>}

            <ToastContainer/>

            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Liste des utilisateurs connectes
                </header>

                <div className=''>

                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nom complet</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">statut</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            users.length > 0 ? 

                            users.map((user,index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.phone}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.email}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.role}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <span className="p-1 rounded-md inline-block bg-green-700 text-white text-xs font-bold">
                                            En ligne
                                        </span>
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                        
                                        <Link to={`/user/${user?.id}`} className="text-indigo-600 hover:text-indigo-900">
                                            <EyeIcon className='w-6'/>
                                        </Link>

                                    </td>
                                </tr>
                            ))

                            :

                            <tr className=''><td>Aucun utilisateur</td></tr>

                        }
                        </tbody>
                    </table>

                </div>

            </article>

            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Liste des utilisateurs non connectes
                </header>

                <div className=''>

                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nom complet</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">statut</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            offlines.length > 0 ? 

                            offlines.map((user,index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.phone}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user?.email}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <span className="p-1 rounded-md inline-block bg-red-700 text-white text-xs font-bold">
                                            Deconnecte
                                        </span>
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                        
                                        <Link to={`/user/${user?.id}`} className="text-indigo-600 hover:text-indigo-900">
                                            <EyeIcon className='w-6'/>
                                        </Link>

                                    </td>
                                </tr>
                            ))

                            :

                            <tr className=''><td>Aucun utilisateur</td></tr>

                        }
                        </tbody>
                    </table>

                </div>

            </article>

        </section>

    )

}