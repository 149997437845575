import { getData, postData } from "../lib/request";
import { DROP_ERRAND, DROP_ERRANDS, GET_ERRAND, GET_ERRANDS } from "../resources/routes.js"

export default class errands {

    static async getErrands(){

        let route = GET_ERRANDS

        let response = await getData(route)

        return response

    }

    static async getErrand(id){

        let route = GET_ERRAND + `/${id}`

        let response = await getData(route)

        // alert(JSON.stringify(response.data.errand))

        return response

    }

    static async dropErrands()
    {

        let response = await postData({},DROP_ERRANDS)

        return response

    }

    static async dropErrand(id)
    {

        let response = await postData({},DROP_ERRAND + `/${id}`)

        return response

    }

}