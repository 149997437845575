import { BuildingOfficeIcon } from "@heroicons/react/20/solid";
import { BookOpenIcon, CakeIcon, CameraIcon, ChartPieIcon, HomeIcon, LinkIcon, TruckIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { AppStore } from "../../App";
import PaymentVault from "../../services/vault.service";
import { useRef } from "react";
import GlobalLoader from "../../components/GlobalLoader";

export default function Stats()
{

    const {store,dispatch} = useContext(AppStore)

    const [amount,setAmount] = useState(0)

    const [type,setType] = useState('')

    const callExecuted = useRef(false)

    const [errandBalance,setErrandBalance] = useState(0)
    const [productBalance,setProductBalance] = useState(0)
    const [rentalBalance,setRentalBalance] = useState(0)
    const [savingBalance,setSavingBalance] = useState(0)
    const [insuranceBalance,setInsuranceBalance] = useState(0)

    const types = [{value:'errand',text:'Compte courses'},{value:'product',text:'Compte produits'}]

    const getErrandBalance = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        PaymentVault.getErrandBalance()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                if(response.status == 200)
                {
                    setErrandBalance(response.data.balance)
                }

            }

        })

    }

    const getProductBalance = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        PaymentVault.getProductBalance()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                if(response.status == 200)
                {
                    // alert(JSON.stringify(response))
                    setProductBalance(response.data.balance)
                }

            }

        })

    }

    const getRentalBalance = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        PaymentVault.getRentalBalance()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                if(response.status == 200)
                {
                    // alert(JSON.stringify(response))
                    setRentalBalance(response.data.balance)
                }

            }

        })

    }

    const getSavingBalance = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        PaymentVault.getSavingBalance()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                if(response.status == 200)
                {
                    // alert(JSON.stringify(response))
                    setSavingBalance(response.data.balance)
                }

            }

        })

    }

    const getInsuranceBalance = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        PaymentVault.getInsuranceBalance()
        .then(response => {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

            if(response)
            {

                if(response.status == 200)
                {
                    // alert(JSON.stringify(response))
                    setInsuranceBalance(response.data.balance)
                    console.log(response.data.balance)
                }

            }

        })

    }

    const handleSubmit = async e => {

        e.preventDefault()


    }

    useEffect(() => {

        if(callExecuted.current === false)
        {

            getErrandBalance()

            getProductBalance()

            getRentalBalance()

            getSavingBalance()

            getInsuranceBalance()

            localStorage.setItem('page','other')

            callExecuted.current = true

        }

    },[])

    return (

        <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <ChartPieIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">comptabilite</span>
                    </div>
                    {/* <div className="">
                        <button onClick={() => dispatch({type : 'TOGGLE_PRODUCT_ADD_INTERFACE', payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter</button>
                    </div> */}
                </header>

                {
                    store.loading && <GlobalLoader/>
                }

                {/* <article className="my-10">

                    <header className="font-extrabold text-lg mb-7">
                        Filtrer <span className="text-xs italic text-purple-800">(Voir les chiffres de la plate-forme sur une periode)</span>
                    </header>

                    <form className="flex gap-2 items-center" onSubmit={handleSubmit}>

                        <div className="w-2/12">
                            <input type="date" className="border border-gray-300 p-2 block w-full rounded-md"/>
                        </div>

                        <div className="w-2/12">
                            <input type="date" className="border border-gray-300 p-2 block w-full rounded-md"/>
                        </div>

                        <div className="w-1/12 flex items-center">
                            <button type="submit" className="bg-blue-700 text-white p-2 rounded-md font-bold">Effectuer</button>
                        </div>

                    </form>

                </article> */}

                <article className="">

                    <div className="grid lg:grid-cols-2 md:gap-8">

                        <div className="shadow p-4 shadow-current bg-[#004665] rounded">

                            <div className="">
                                <TruckIcon className="w-6 inline-flex text-[#2c82a7]"/>
                                <span className="font-bold pl-2 text-[#2c82a7]">VTC</span>
                            </div>

                            <div className="text-[#2c82a7] py-4 font-extrabold text-2xl">
                                <span>{errandBalance} FCFA</span>
                            </div>

                        </div>

                        <div className="shadow p-4 shadow-current bg-[#d48047] rounded">

                            <div className="">
                                <BookOpenIcon className="w-6 inline-flex text-[#96572e]"/>
                                <span className="font-bold pl-2 text-[#96572e]">Produits</span>
                            </div>

                            <div className="text-[#96572e] py-4 font-extrabold text-2xl">
                                <span>{productBalance} FCFA</span>
                            </div>

                        </div>

                        <div className="shadow p-4 shadow-current bg-[#145c20] rounded">

                            <div className="">
                                <HomeIcon className="w-6 inline-flex text-[#41ac37]"/>
                                <span className="font-bold pl-2 text-[#41ac37]">Appartements</span>
                            </div>

                            <div className="text-[#41ac37] py-4 font-extrabold text-2xl">
                                <span>{rentalBalance} FCFA</span>
                            </div>

                        </div>

                        <div className="shadow p-4 shadow-current bg-sky-700 rounded">

                            <div className="">
                                <CakeIcon className="w-6 inline-flex text-sky-300"/>
                                <span className="font-bold pl-2 text-sky-300">Produit epargne</span>
                            </div>

                            <div className="text-sky-300 py-4 font-extrabold text-2xl">
                                <span>{savingBalance} FCFA</span>
                            </div>

                        </div>

                        <div className="shadow p-4 shadow-current bg-cyan-950 rounded">

                            <div className="">
                                <CakeIcon className="w-6 inline-flex text-cyan-300"/>
                                <span className="font-bold pl-2 text-cyan-300">Produit assurance</span>
                            </div>

                            <div className="text-cyan-300 py-4 font-extrabold text-2xl">
                                <span>{insuranceBalance} FCFA</span>
                            </div>

                        </div>

                    </div>

                </article>

        </section>

    )

}