import React, { useContext, useEffect, useRef, useState } from "react"
import { AppStore } from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckBadgeIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon, PencilIcon, ShoppingBagIcon, TruckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import CarService from "./car.service";
import GlobalLoader from "../../components/GlobalLoader";
import Spinner from "../../components/Spinner";
import ImageZoom from "../../components/ImageZoom";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function LeasableCars()
{

    const {store,dispatch} = useContext(AppStore)

    const [zoomActive,setZoomActive] = useState(false)

    const [cars,setCars] = useState([])

    const [link,setLink] = useState(null)

    const [targetedCar,setTargetedCar] = useState(null)

    const [toggleUpdate,setToggleUpdate] = useState(false)

    const [insurance,setInsurance] = useState(null)

    const [grayCard,setGrayCard] = useState(false)

    const [frontpic,setFrontPic] = useState(null)

    const [backpic,setBackPic] = useState(null)

    const [gallery,setGallery] = useState([])

    const [loading,setLoading] = useState(false)

    const [carsMemory,setCarsMemory] = useState([])

    const [listIndex,setListIndex] = useState(0)

    const remakeData = (reference,n) => {
        const newData = reference.reduce((acc, curr, index) => {
          const chunkIndex = Math.floor(index / n);
          if (!acc[chunkIndex]) {
            acc[chunkIndex] = [];
          }
          acc[chunkIndex].push(curr);
          return acc;
        }, []);
        return newData
    };

    const fetchLeasableCars = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        await CarService.getLeasableCars()
        .then(res => {

            if(res)
            {

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

                // Remake the data

                console.log(remakeData(res.data.cars,10))

                setCars(remakeData(res.data.cars,10))

            }

        })

    }

    const setPendingCar = async (e,id,status) => {
        
        e.preventDefault()

        let text = status == 0 ? 'activer' : 'desactiver'

        let alt = status == 0 ? 'active' : 'desactive'

        if(window.confirm(`Voulez vous vraiment ${text} ce vehicule ?`))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data = {
                id : id
            }
    
            let response = await CarService.setPendingCar(data)
    
            if(response.status == 200)
            {
                toast.success(`Le véhicule est maintenant ${alt}`)
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                await fetchLeasableCars()
            }

        }

    }

    const setZoom = (e,link) => {

        e.preventDefault()

        dispatch({type : 'SET_ZOOM',payload : true})

        setLink(link)

    }

    const deleteLeasableCar = async (e,id) => {
        
        e.preventDefault()

        if(window.confirm('Voulez vous vraiment supprimer ce vehicule ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data = {
                id : id
            }
    
            let response = await CarService.deleteLeasableCar(data)
    
            if(response.status == 200)
            {
                toast.success('Le véhicule est maintenant active')
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                fetchLeasableCars()
            }

        }

    }

    const triggerCarUpdate = (e,car) => {

        e.preventDefault()

        setTargetedCar(car)
        setToggleUpdate(true)

    }

    const hideUpdateInterface = () => {

        setToggleUpdate(false)
        setTargetedCar(null)

    }

    const removeImage = async (id) => {

        let data = {
            id : id
        }

        if(window.confirm('Voulez vous vraiment supprimer cette image ?'))
        {

            let response = await CarService.removeImage(data)

            if(response)
            {
    
                if(response.status == 200)
                {
    
                    setTargetedCar({...targetedCar,gallery : response.data.locomotion.gallery})
                    toast.success('Image supprimee avec succes')
    
                }
    
            }

        }

    }

    const handleCarUpdate = async (e) => {
        
        e.preventDefault()

        let data = {
            locomotion_id : targetedCar.id,
            front_pic : frontpic,
            back_pic : backpic,
            files : gallery,
            pic_insurance : insurance,
            pic_carte_grise : grayCard,
            brand : targetedCar.brand,
            color : targetedCar.color,
            daily_price : targetedCar.daily_price,
            year : targetedCar.year,
            sets : targetedCar.sets,
            engine : targetedCar.engine
        }

        if(window.confirm('Voulez vous poursuire cette operation ?'))
        {

            setLoading(true)

            let response = await CarService.updateCar(data)

            if(response)
            {

                if(response.status == 200)
                {

                    hideUpdateInterface()
                    toast.success('Mise a jour effectuee avec succes')

                }
                else{
                    console.log(response)
                }

            }

            setLoading(false)

        }

    }

    const filterCars = query => {

        setCarsMemory(carsMemory.length > 0 ? carsMemory : cars)

        if(query == '')
        {
            setCars(carsMemory)
        }
        else{

            let mergedArrray = []

            let count = 0

            if(carsMemory.length > 0)
            {

                for(var i = 0; i < carsMemory.length;i++)
                {
                    for(var j = 0;j < carsMemory[i].length;j++)
                    {
                        mergedArrray[count] = carsMemory[i][j]
                        count += 1
                    }
                }

            }
            else{

                for(var i = 0; i < cars.length;i++)
                {
                    for(var j = 0;j < cars[i].length;j++)
                    {
                        mergedArrray[count] = cars[i][j]
                        count += 1
                    }
                }

            }

            console.log(mergedArrray)

            setCars(remakeData(mergedArrray.filter(car => car.theowner.c_name.toLowerCase().includes(query.toLowerCase())),10))

        }

    }

    useEffect(() => {

        fetchLeasableCars()  
        localStorage.setItem('page','other')

    },[])

    return (

        <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <TruckIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Véhicules en location</span>
                    </div>
                </header>

                <ToastContainer/>

                {loading && <GlobalLoader/>}

                {store.loading ? <Spinner/> : 

                    <article className="w-full">

                        <div className="bg-orange-600 text-black font-bold my-10 p-5">
                            <ExclamationTriangleIcon className="w-6 inline-flex text-black"/>
                            <span className="pl-2">Veuillez contacter le proprietaire avant de supprimer un vehicule. Ledit vehicule pourrait etre celui 
                            qu'il utilise pour faire des courses si il est un conducteur actif sur la plate-forme.</span>
                        </div>

                        <div className="mb-10">

                            <input onChange={e => filterCars(e.target.value)} placeholder="Rechercher un vehicule par le nom du proprietaire" className="block bg-transparent border border-gray-300 rounded-md p-3 w-full focus:outline-none"/>

                        </div>

                        {
                            cars.length == 0 ?

                            <div>Aucun véhicules en attente</div> : 

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    #
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Utilisateur
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Role
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Marque
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image avant
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image arriere
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Statut
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Action
                                    </th>
                                </tr>
                                </thead> 
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {cars[listIndex].map((car,index) => (
                                    <tr key={index}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {index + 1}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.theowner?.c_name}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.theowner?.role}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.brand}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.front_pic}`)}  className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.back_pic}`)} className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            
                                            {car?.validated == 0 ? 'En attente' : 'Validé'}

                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                            <a onClick={e => triggerCarUpdate(e,car)} className="bg-blue-600 hover:bg-blue-900 cursor-pointer text-white p-2 rounded flex items-center gap-1">
                                                <PencilIcon className="w-4"/>
                                                <span className="">Modifier</span>
                                            </a>
                                            <a title="Desactiver ce vehicule" onClick={e => setPendingCar(e,car?.id,car.validated)}  className="bg-gray-600 hover:bg-gray-900 text-white rounded-md p-2 cursor-pointer flex items-center gap-1">
                                                {
                                                    car.validated ? <EyeSlashIcon className="w-4"/> : ''
                                                }
                                                {
                                                    !car.validated ? <EyeIcon className="w-4"/> : ''
                                                }
                                                {
                                                    car.validated ? 'Desactiver' : 'Activer'
                                                }
                                            </a>
                                            <a onClick={e => deleteLeasableCar(e,car?.id)}  className="bg-red-600 hover:bg-red-900 text-white rounded-md p-2 cursor-pointer flex items-center gap-1">
                                                <TrashIcon className="w-4"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        }

                        {store.userDetails.zoomActive && <ImageZoom link={link}/>}

                        <div className="flex items-center gap-10 mt-5">

                            <header className="font-bold">Pagination</header>

                            <div className="flex gap-2">

                                {
                                    cars.map((chunk,index) => 
                                        <React.Fragment>

                                            <button onClick={e => setListIndex(index)} className={index == listIndex ? "bg-gray-400 inline-flex items-center justify-center w-8 h-8 text-white text-sm font-bold cursor-default" : "bg-blue-600 inline-flex items-center justify-center w-8 h-8 text-white text-sm font-bold"}>{index}</button>

                                        </React.Fragment>
                                    )
                                }

                            </div>

                        </div>

                    </article>

                }

                {
                    toggleUpdate ? 
                    <section onClick={e => hideUpdateInterface()} className="fixed bg-black/50 inset-0 flex items-center justify-center z-30">

                        <article onClick={e => e.stopPropagation()} className="bg-white w-7/12 h-[600px] overflow-y-auto p-10">

                            <form className="" onSubmit={e => handleCarUpdate(e)}>

                                <header className="mb-5 font-extrabold text-lg">
                                    Informations textuelles
                                </header>

                                <div className="pb-4">
                                    <label className="block py-4">Marque <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="text" value={targetedCar.brand} name="link" onChange={e => setTargetedCar({...targetedCar,brand : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Marque du vehicule`}/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-4">Annee <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="text" value={targetedCar.year} name="link" onChange={e => setTargetedCar({...targetedCar,year : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Annee de production`}/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-4">Couleur <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="text" value={targetedCar.color} name="link" onChange={e => setTargetedCar({...targetedCar,color : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Couleur du vehicule`}/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-4">Nombres de place <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="text" value={targetedCar.sets} name="link" onChange={e => setTargetedCar({...targetedCar,sets : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Nombre de place`}/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-4">Moteur <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="text" value={targetedCar.engine} name="link" onChange={e => setTargetedCar({...targetedCar,engine : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Type de moteur`}/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-4">Prix journalier <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="number" value={targetedCar.daily_price} name="link" onChange={e => setTargetedCar({...targetedCar,daily_price : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Prix journalier`}/>
                                </div>

                                <header className="mb-5 font-extrabold text-lg">
                                    Images
                                </header>

                                <div className="pb-4">
                                    <label className="block py-1">Image de l'assurance <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <label className="block py-2">
                                        <a href={targetedCar.pic_insurance} target="_blank" className="underline text-blue-600">Voir</a>
                                    </label>
                                    <input type="file" name="link" onChange={e => setInsurance(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-1">Image de la carte grise <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <label className="block py-2">
                                        <a href={targetedCar.pic_carte_grise} target="_blank" className="underline text-blue-600">Voir</a>
                                    </label>
                                    <input type="file" name="link" onChange={e => setGrayCard(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-1">Image avant <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <label className="block py-2">
                                        <a href={targetedCar.front_pic} target="_blank" className="underline text-blue-600">Voir</a>
                                    </label>
                                    <input type="file" name="link" onChange={e => setFrontPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                </div>

                                <div className="pb-4">
                                    <label className="block py-1">Image arriere <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <label className="block py-2">
                                        <a href={targetedCar.back_pic} target="_blank" className="underline text-blue-600">Voir</a>
                                    </label>
                                    <input type="file" name="link" onChange={e => setBackPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                </div>

                                <header className="mb-5 font-extrabold text-lg">
                                    Gallerie d'images
                                </header>

                                {
                                    targetedCar.gallery.length > 0 ?
                                    <div className="grid grid-cols-5 my-5">

                                        {
                                            targetedCar.gallery.map((image,index) => {

                                                return (
                                                    <div key={index} className="h-36 w-36 pb-4 rounded-md overflow-hidden relative">
                                                        
                                                        <img src={image.pic} className="w-full"/>

                                                        <div className="bg-black/40 absolute inset-0">
                                                            <button type="button" onClick={() => removeImage(image.id)} className="absolute right-2 top-2 bg-red-600 text-white p-2 rounded">
                                                                <TrashIcon className="w-4 text-white"/>
                                                            </button>
                                                        </div>

                                                    </div>
                                                )

                                            })
                                        }

                                    </div>
                                    :
                                    ''
                                }

                                <div className="pb-4">
                                    <label className="block py-1">Choisissez une ou plusieurs images <span className="text-xs font-bold text-red-600">(Mise a jour facultative)</span></label>
                                    <input type="file" multiple name="link" onChange={e => setGallery(e.target.files)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"/>
                                </div>

                                <div>
                                    <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Valider</button>
                                </div>

                            </form>

                        </article>

                    </section>
                    :
                    ''
                }
            
        </section>

    )

}