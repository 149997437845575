
import React from "react";
import API_BASE from "../resources/env";
import axios from "axios";

const postData = async (data,url,type = null) => {

    try {

        var response = null

        if(!sessionStorage.getItem('access_token'))
        {

            response = await axios.post(`${API_BASE}${url}`,data)

        }
        else{

            response = await axios.post(`${API_BASE}${url}`,data,{
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                    'Content-Type': type ? type : 'application/json',
                  },
            })

        }

        return response;
        
    } catch (error) {
        return error.response
    }

}

const getData = async (url) => {

    try {

        var response = null

        

        if(!sessionStorage.getItem('access_token'))
        {

            response = await axios.get(`${API_BASE}${url}`)

        }
        else{

            response = await axios.get(`${API_BASE}${url}`,{
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                  },
            })

        }

        return response;
        
    } catch (error) {
        return error.response
    }

}

export {postData,getData}