import React, { useContext, useEffect, useRef, useState } from 'react'

import {
    ArrowRightCircleIcon,
    BanknotesIcon, CheckBadgeIcon, TrashIcon
  } from '@heroicons/react/24/outline'
import useRequest from '../../hooks/useRequest'
import { GET_DEPOSITS, GET_WITHDRAWALS, HANDLE_DEPOSITE } from '../../resources/routes'
import { AppStore } from '../../App'
import GlobalLoader from '../../components/GlobalLoader'
import TrxService from '../../services/trx.service'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Transactions({ctx_memo}) {

    const [granted,setGranted] = useState(false)

    const {store,dispatch} = useContext(AppStore)
    
    const [loading,setLoading] = useState(false)

    const {getData, postData} = useRequest()

    const callExecuted = useRef(false)

    const navigate = useNavigate()

    const fetch_transactions = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_DEPOSITS)

        if(response)
        {

            if(response.status == 200)
            {
                // alert(JSON.stringify(response.data.transactions))
                dispatch({type : 'SET_TRANSACTIONS',payload : response.data.transactions})
            }
            else{
                alert(JSON.stringify(response))
            }

        }
        else{
            console.log('Connexion error')
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const fetch_withdrawals = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_WITHDRAWALS)

        if(response)
        {
            if(response.status == 200)
            {
                // alert(JSON.stringify(response.data))
                dispatch({type : 'SET_WITHDRAWALS',payload : response.data.withdrawals})
            }
            else{
                // alert(JSON.stringify(response))
            }
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const validate_deposit = async (e,id) => {

        e.preventDefault()

        if(window.confirm('Voulez vous vraiment autoriser cette opération ?'))
        {

            let data = {
                trx_id : id,
                status : 1
            }

            let response = await postData(data,HANDLE_DEPOSITE)

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            if(response.status == 200)
            {
                alert('Dépôt accepté, compte crédité')
                setTimeout(() => window.location.reload(),1000)
            }
            else{
                alert(JSON.stringify(response))
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const handle_withdrawals = async (e,trx_id) => {

        e.preventDefault()

        if(window.confirm('Voulez vous vraiment autoriser ce retrait ?'))
        {

            let data = {
                status : 1,
                trx_id : trx_id
            }

            let response = await postData(data,HANDLE_DEPOSITE)

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            if(response.status == 200)
            {
                alert('Retrait effectue, compte defalque avec succes')
                setTimeout(() => window.location.reload(),1000)
            }
            else{
                alert(JSON.stringify(response))
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const deleteTransaction = async (e,id) => {

        e.preventDefault()

        if(window.confirm('Voulez vous vraiment supprimer cette opération ?'))
        {
            
            let data = {
                id : id
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let response = await TrxService.deleteTransaction(data)

            if(response.status == 200)
            {
                toast.success('Opération supprimée avec success')
                await fetch_transactions()
                await fetch_withdrawals()
            }

            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const password_check = async () => {

        try {

            if(localStorage.getItem('page') == 'trx')
            {

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                setGranted(true)
                localStorage.setItem('page','trx')

            }
            else{
                const password = window.prompt('Veuillez fournir le mot de passe avant d\'acceder a la page')

                if(password == '')
                {
                    dispatch({type : 'TOGGLE_APP_LOADING', payload : true})
                    password_check()
                }
                else{

                    let data = {
                        password : password
                    }

                    dispatch({type : 'TOGGLE_APP_LOADING', payload : true})
            
                    let response = await TrxService.checkPassword(data)
            
                    if(response)
                    {
                        if(response.status == 200)
                        {
                
                            if(!response.data.granted)
                            {
                                alert('Mot de passe incorrect')

                                if(window.confirm('Voulez vous reessayer ?'))
                                {
                                    password_check()
                                }
                                else{
                                    alert('Vous allez etre redirige vers la page d\'accueil')
                                    navigate('/')
                                }

                            }
                            else{
                                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                                setGranted(true)
                                localStorage.setItem('page','trx')
                            }
                
                        }
                    }
                    else{
                        password_check()
                    }

                }
            }
            
        } catch (error) {

            console.log(error)
            
        }

    }

    useEffect(() => {
        

        if(!callExecuted.current)
        {

            setTimeout(() => {

                password_check()
                .then(() => {

                    fetch_transactions()

                    fetch_withdrawals()

                })

                localStorage.setItem('page','other')
                
            }, 200);

            callExecuted.current = true

        }

    },[])

    return (

        <>

            {
                granted && 

                <section className="w-full">

                    <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                        <div className="">
                            <BanknotesIcon className="w-6 inline-flex text-black"/>
                            <span className="font-bold pl-2 text-black">Transactions</span>
                        </div>
                        {/* <div className="">
                            <button className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                        </div> */}
                    </header>

                    <ToastContainer/>

                    {store.loading && <GlobalLoader/>}

                    <article className=''>

                        <header className='text-lg font-bold'>
                            Recharges en attente
                        </header>

                        <div className=''>

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Utilisateur</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Capture</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {
                                    store.transactions.transactions.length > 0 ? 

                                    store.transactions.transactions.map(transaction => (
                                        <tr key={transaction.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {transaction.id}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.user.c_name}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <a target='_blank' href={transaction.pic} className="bg-blue-800 text-white px-4 py-1 rounded-lg">
                                                    <span className=''>Voir</span>
                                                    <ArrowRightCircleIcon className='w-4 ml-2 inline-block'/>
                                                </a>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.amount}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                                
                                                <a href="" onClick={e => validate_deposit(e,transaction.id)} id={`a_${transaction.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                    <CheckBadgeIcon className='w-6'/>
                                                </a>

                                                <a onClick={e => deleteTransaction(e,transaction.id)} href="" className="text-red-600 hover:text-red-900">
                                                    <TrashIcon className='w-6'/>
                                                </a>

                                            </td>
                                        </tr>
                                    ))

                                    :

                                    <tr className=''>Aucune demande de recharge</tr>

                                }
                                </tbody>
                            </table>

                        </div>

                    </article>

                    <article className='mt-14'>

                        <header className='text-lg font-bold'>
                            Retraits en attente
                        </header>

                        <div className=''>

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Utilisateur</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {
                                    store.transactions.withdrawals.length > 0 ? 

                                    store.transactions.withdrawals?.map(withdrawal => (
                                        <tr key={withdrawal.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {withdrawal.id}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{withdrawal.user.c_name}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{withdrawal?.amount}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                                
                                                <a href="" onClick={e => handle_withdrawals(e,withdrawal.id)} className="text-indigo-600 hover:text-indigo-900">
                                                    <CheckBadgeIcon className='w-6'/>
                                                </a>

                                                <a onClick={e => deleteTransaction(e,withdrawal.id)} href="" className="text-red-600 hover:text-red-900">
                                                    <TrashIcon className='w-6'/>
                                                </a>

                                            </td>
                                        </tr>
                                    ))

                                    :

                                    <tr className=''>Aucune demande de retrait</tr>

                                }
                                </tbody>
                            </table>

                        </div>

                    </article>

                </section>

            }

        </>

    )
    
}