import { BuildingOffice2Icon, CheckBadgeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import house from "../services/house.service";
import GlobalLoader from "./GlobalLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function HouseManagement()
{

    const [houses,setHouses] = useState([])

    const [loading,setLoading] = useState(false)

    const callExecuted = useRef(false)

    const get_houses = async () => {

        setLoading(true)

        let response = await house.getBulkyHouses();

        if(response)
        {

            if(response.status == 200)
            {
                setHouses(response.data)
                console.log(response.data)
            }

        }

        setLoading(false)

    }

    const toggleBulkyHouses = async (id,type) => {

        let data = {
            id : id,
            type : type
        }

        setLoading(true)

        let response = await house.bulkyToggleHouses(data)

        if(response)
        {

            if(response.status == 200)
            {
                toast.success('Operation effectuee avec success')
                get_houses()
            }
            else{
                toast.error('Something went wrong')
                console.log(response)
            }

        }

        setLoading(false)

    }

    const deleteBulkyHouses = async (id,type) => {

        let data = {
            id : id,
            type : type
        }

        if(window.confirm('Voulez vous vraiment supprimer cet enregistrement ?'))
        {
            
            setLoading(true)

            let response = await house.bulkyDeleteHouses(data)

            if(response)
            {

                if(response.status == 200)
                {
                    toast.success('Operation effectuee avec success')
                    get_houses()
                }
                else{
                    toast.error('Something went wrong')
                    console.log(response)
                }

            }

            setLoading(false)

        }

    }

    useEffect(() => {

        if(!callExecuted.current)
        {
            get_houses()
            callExecuted.current = true
        }
        
    },[])

    return (

        <section>

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BuildingOffice2Icon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Gestion des maisons a louer</span>
                </div>
            </header>

            {loading && <GlobalLoader/>}

            <ToastContainer/>
            
            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Appartements en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix journalier</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        houses?.flats?.length > 0 ? 

                        houses?.flats?.map((flat,index) => (
                            <tr key={flat.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.prop?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.prop?.tel}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.daily_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{flat.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <button onClick={() => toggleBulkyHouses(flat.id,'flat')} className={`${flat?.validated ? "bg-red-800" : "bg-green-800"} text-white font-semibold p-2 rounded-md flex items-center gap-2`}>
                                        {
                                            flat?.validated ?
                                            'Desactiver' :
                                            'Activer'
                                        }
                                    </button>

                                    <button onClick={() => deleteBulkyHouses(flat.id,'flat')} className="bg-red-600 hover:bg-red-900 p-2 text-white flex items-center gap-1 rounded-md">
                                        <TrashIcon className='w-4'/>
                                        <span className="">Supprimer</span>
                                    </button>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucun appartement en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>

            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Guesthouses en attente d'activation
                </header>

                <div className=''>

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Proprietaire</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tel</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Prix journalier</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salons</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chambres</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Douches</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        houses?.guesthouses?.length > 0 ? 

                        houses?.guesthouses?.map((guesthouse,index) => (
                            <tr key={guesthouse.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.prop?.c_name}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.prop?.phone}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.daily_price}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.saloon}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.rooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{guesthouse.bath}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                    
                                    <button onClick={() => toggleBulkyHouses(guesthouse.id,'guest')} className={`${guesthouse?.activated ? "bg-red-800" : "bg-green-800"} text-white font-semibold p-2 rounded-md flex items-center gap-2`}>
                                        {
                                            guesthouse?.activated ?
                                            'Desactiver' :
                                            'Activer'
                                        }
                                    </button>

                                    <button onClick={() => deleteBulkyHouses(guesthouse.id,'guest')} className="bg-red-600 hover:bg-red-900 p-2 text-white flex items-center gap-1 rounded-md">
                                        <TrashIcon className='w-4'/>
                                        <span className="">Supprimer</span>
                                    </button>

                                </td>
                            </tr>
                        ))

                        :

                        <tr className=''>Aucune guest house en attente</tr>

                    }
                    </tbody>
                </table>

                </div>

            </article>

        </section>

    )

}