import { getData, postData } from "../lib/request";
import { GET_ERRAND_BALANCE, GET_INSURANCE_BALANCE, GET_PRODUCT_BALANCE, GET_RENTAL_REAL_ESTATE_BALANCE, GET_SAVING_BALANCE, MONEY_WITHDRAWAL } from "../resources/routes";


export default class PaymentVault {

    static async getErrandBalance ()
    {

        let response = await getData(GET_ERRAND_BALANCE)

        return response

    }

    static async getProductBalance ()
    {

        let response = await getData(GET_PRODUCT_BALANCE)

        return response

    }

    static async getRentalBalance ()
    {

        let response = await getData(GET_RENTAL_REAL_ESTATE_BALANCE)

        return response

    }

    static async getSavingBalance ()
    {

        let response = await getData(GET_SAVING_BALANCE)

        return response

    }

    static async getInsuranceBalance ()
    {

        let response = await getData(GET_INSURANCE_BALANCE)

        return response

    }

}