import React, { useContext, useEffect, useRef, useState } from "react";
import { AppStore } from "../../App";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import GlobalLoader from "../../components/GlobalLoader";
import TrxService from "../../services/trx.service";

export default function History()
{

    const {store,dispatch} = useContext(AppStore)

    const [transactions,setTransactions] = useState([])

    const callExecuted = useRef(false)

    const getHistory = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await TrxService.getHistory()

        if(response)
        {

            if(response.status == 200)
            {
                setTransactions(response.data.transactions)
            }

        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    useEffect(() => {

        if(!callExecuted.current)
        {

            getHistory()

            localStorage.setItem('page','other')

            callExecuted.current = true

        }

    },[])

    return (

        <React.Fragment>

            <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg">
                    <ChartBarIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Historique des transactions</span>
                </header>

                {store.loading && <GlobalLoader/>}
                
                <article className='mt-10'>

                    <div className=''>

                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Utilisateur</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            transactions.length > 0 ? 

                            transactions.map((transaction,index) => (
                                <tr key={transaction.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.user?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.trx_type.name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.amount}</td>
                                </tr>
                            ))

                            :

                            <tr className=''>Aucune transaction</tr>

                        }
                        </tbody>
                    </table>

                    </div>

                </article>
                

            </section>

        </React.Fragment>

    )

}