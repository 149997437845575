import { BellAlertIcon } from "@heroicons/react/16/solid";
import { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userService } from "../../services/user.service"
import { AppStore } from "../../App"
import GlobalLoader from "../../components/GlobalLoader";
import { socket } from "../../services/socket.service";

export default function Notification()
{

    const {store,dispatch} = useContext(AppStore)

    const [roles,setRoles] = useState([])

    const [selectedRole,setSelectedRole] = useState(null)

    const [message,setMessage] = useState('')

    const [title,setTitle] = useState('')

    const callExecuted = useRef(false)

    const fetchRoles = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await userService.getRoles()

        if(response.status == 200)
        {

            setRoles(response.data.roles)

        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const handleSubmit = async e => {

        e.preventDefault()

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let data = {
            message : message,
            role : selectedRole,
            title : title
        }

        let response = await userService.broadcastMessage(data)

        console.log(socket.connected)

        socket.connect()

        socket.emit('broadcast_notifications',{
            title : title,
            message : message,
            targets : response.data.targets
        });

        setTitle('')
        setMessage('')

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        toast.success('Message diffuse avec succes')

    }

    useEffect(() => {

        if(!callExecuted.current)
        {

            if(socket.connected)
            {
                console.log('Socket connected')
            }

            fetchRoles()
            localStorage.setItem('page','other')

            callExecuted.current = true

        }

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                <div className="">
                    <BellAlertIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Notifications</span>
                </div>
            </header>

            {store.loading && <GlobalLoader/>}

            <ToastContainer/>

            <article className="">

                <form className="w-8/12" onSubmit={handleSubmit}>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Cibles</label>
                        <select required className="bg-white border border-gray-300 p-2 w-full focus:outline-none rounded-md" onChange={e => setSelectedRole(e.target.value)}>

                            <option></option>

                            <option value={`all`}>Tout</option>

                            <option value={`client`}>Client</option>

                            {
                                roles.map(role => (
                                    <option value={role.name}>{role.name}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Titre du message</label>
                        <input value={title} type="text" required name="name" onChange={e => setTitle(e.target.value)} className="bg-white border border-gray-300 p-2 w-full focus:outline-none rounded-md"  placeholder="Contenu de l'annonce"/>
                    </div>

                    <div className="pb-4">
                        <label className="block py-4 font-extrabold">Message</label>
                        <textarea value={message} required name="name" onChange={e => setMessage(e.target.value)} className="bg-white border border-gray-300 p-2 w-full focus:outline-none rounded-md min-h-[200px]"  placeholder="Contenu de l'annonce"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white rounded-md font-bold">Diffuser</button>
                    </div>

                </form>

            </article>

        </section>

    )

}