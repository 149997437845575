import { BuildingStorefrontIcon, EyeIcon, InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { AppStore } from "../../App";
import { useContext, useEffect, useRef, useState } from "react";
import house from "../../services/house.service";
import GlobalLoader from "../../components/GlobalLoader";


export default function ImmoManagement()
{

    const {store,dispatch} = useContext(AppStore)

    const [displayDetails,setDisplayDetails] = useState(false)

    const [targetedHouse,setTargetedHouse] = useState({})

    const [houses,setHouses] = useState([])

    const loaded = useRef(false)

    const fetchManagedHouses = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await house.getManagedHouses()

        if(response)
        {

            if(response.status == 200)
            {
                setHouses(response.data.houses)
                console.log(response.data.houses)
            }

        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const handleClick = (house) => {

        setTargetedHouse(house)
        setDisplayDetails(true)

    }

    useEffect(() => {

        if(loaded.current == false)
        {
            fetchManagedHouses()
            loaded.current = true
        }
        
    })

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <BuildingStorefrontIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Gestion immobiliers</span>
                </div>
                {/* <div className="">
                    <button className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div> */}
            </header>

            {store.loading && <GlobalLoader/>}

            <article className="">

                <header className='text-lg font-bold'>
                    Liste des maisons sous gestion
                </header>

                <article className="w-full">

                    {
                        houses.length == 0 ?

                        <div>Aucun partenaire en attente</div> : 

                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                #
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Code
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Numero
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Proprietaire
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Locataire
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Loyer mensuel
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Total paye
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Action
                                </th>
                            </tr>
                            </thead> 
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {houses.map((house,index) => (
                                <tr key={house?.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {index + 1}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {house?.house.code}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {house?.number}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {house?.house.landlord.c_name}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {house?.lease?.tenant?.c_name ?? 'Aucun locataire'}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {house?.monthly_price} FCFA
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        {(
                                            () => {
                                                let total = 0
                                                house?.transactions.forEach(payment => {
                                                    total += payment.amount
                                                })
                                                return total
                                            }
                                        )()} FCFA
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">

                                        <button onClick={e => handleClick(house)} href="" className="bg-green-600 px-4 py-2 text-sm rounded cursor-pointer text-white">
                                            <EyeIcon className="w-6 text-white cursor-pointer"/>
                                            <span className="">Details</span>
                                        </button>

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    }

                    </article> 

                    {
                        displayDetails == true ? 

                        <section onClick={e => setDisplayDetails(false)} id="house_details" className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
                            
                            <article onClick={e => e.stopPropagation()} className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[200px]">

                                {/* Show bills paid over here */}

                                {
                                    targetedHouse.transactions.length > 0 ?
                                    <table className="w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            #
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Locataire
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Montant
                                            </th>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Mois
                                            </th>
                                        </tr>
                                        </thead> 
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {targetedHouse.transactions.map((trx,index) => (
                                            <tr key={trx?.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {index + 1}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    {trx?.user?.c_name}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    {trx?.amount}
                                                </td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    {trx?.amount}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table> 
                                    :
                                    <p className="text-gray-700 py-10">
                                        <span className="inline-flex gap-2 items-center">
                                            <InformationCircleIcon className="w-6 text-gray-700"/>
                                            <span className="font-bold">Aucun paiement effectué</span>
                                        </span>
                                    </p>
                                }

                            </article>

                        </section>

                        : ''
                    }

            </article>

        </section>

    )

}