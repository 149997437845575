import { PencilIcon, TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DELETE_DISCOUNT, GET_DISCOUNTS,UPDATE_DISCOUNT } from "../../resources/routes";
import { useContext, useEffect, useState } from "react";
import { AppStore } from "../../App";
import useRequest from "../../hooks/useRequest";
import GlobalLoader from "../../components/GlobalLoader";
import CreatePromo from "../../components/Promo/CreatePromo";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Discount({data})
{

    const {store,dispatch} = useContext(AppStore)

    const [code,setCode] = useState(null)

    const {getData,postData} = useRequest()

    const [targetPromo,setTargetPromo] = useState({})

    const [updating,setUpdating] = useState(false)

    const fetch_discounts = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_DISCOUNTS)

        if(response.status == 200)
        {
            // alert(JSON.stringify(response.data.discounts))
            dispatch({type : 'SET_DISCOUNTS',payload : response.data.discounts})
            
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    const deletePromo = async (id) => {

        if(window.confirm('Voulez vous vraiment supprimer ce code promo ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let response = await postData({},DELETE_DISCOUNT + '/' + id)
    
            if(response.status == 200)
            {
                // alert(JSON.stringify(response.data.discounts))
                fetch_discounts()
                
            }
    
            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const setTarget = (target) => {

        setTargetPromo(target)

        setUpdating(true)

    }

    function generateRandomCode(length) {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset[randomIndex];
        }
        return result;
    }

    const generate_code = e => {

        e.preventDefault()

        setTargetPromo({
            ...targetPromo,
            code : generateRandomCode(8)
        })

    }

    const handleSubmit = async e => {

        e.preventDefault()

        if(!targetPromo.code && !targetPromo.percentage)
        {
            toast.warning('Veuillez fournir les donnees')
        }
        else{

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data  = {
                code : targetPromo.code,
                percentage : targetPromo.percentage,
                id : targetPromo.id,
                exp_date : targetPromo.exp_date
            }

            let response = await postData(data,UPDATE_DISCOUNT)

            if(response.status == 200)
            {


                toast.success('Code promo mis a jour avec succès')

                await fetch_discounts()

                setUpdating(false)

            }
            else{
                alert(JSON.stringify(response))
            }

            setUpdating(false)

        }

    }
 
    return (

        <div className="shadow shadow-gray-500 p-4">

            <div className="">
                <div className="">
                    <b className="">Code : </b> {data.code}
                </div>
                <div className="my-4">
                    <b className="">Reduction : </b> {data.percentage}%
                </div>
                <div className="my-4">
                    <b className="">Expiration : </b> {data.exp_date}
                </div>
            </div>

            <div className="flex gap-5">

                <button onClick={e => deletePromo(data.id)} className="">
                    <TrashIcon className="w-5 text-red-700"/>
                </button>

                <button onClick={e => setTarget(data)} className="">
                    <PencilIcon className="w-5 text-blue-700"/>
                </button>
                
            </div>

            {
                updating ? 
                <section onClick={e => setUpdating(false)} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">

                    <article onClick={e => e.stopPropagation()} className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                        <form onSubmit={handleSubmit} name="validationForm">

                        {store.loading && <GlobalLoader/>}

                        <ToastContainer/>

                            <header className="font-bold text-lg">Ajouter un produit</header>

                            <div className="pb-4">
                                <label className="block py-4">Code</label>
                                <a onClick={generate_code} className="text-blue-700 underline pb-4 block text-sm cursor-pointer">Generer le code</a>
                                <input type="text" name="color" value={targetPromo.code} onChange={e => setTargetPromo({...targetPromo,code : e.target.value})} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Code"/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Pourcentage</label>
                                <input type="number" value={targetPromo.percentage} name="color" onChange={e => setTargetPromo({...targetPromo,percentage : e.target.value})} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Pourcentage"/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Date d'expiration</label>
                                <input type="number" value={targetPromo.exp_date} name="color" onChange={e => setTargetPromo({...targetPromo,exp_date : e.target.value})} className="bg-transparent border border-gray-400 p-3 w-full focus:outline-none"  placeholder="Date d'expiration"/>
                            </div>

                            <div>
                                <button type="submit" className="bg-[#2e7ba5] p-2 pr-10 text-white">Effectuer</button>
                            </div>
                            
                        </form>

                    </article>

                </section>
                :
                ''
            }

        </div>

    )

}

export default function Promo()
{

    const {store,dispatch} = useContext(AppStore)

    const {getData,postData} = useRequest()

    const fetch_discounts = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        let response = await getData(GET_DISCOUNTS)

        if(response.status == 200)
        {
            // alert(JSON.stringify(response.data.discounts))
            dispatch({type : 'SET_DISCOUNTS',payload : response.data.discounts})
            
        }

        dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

    }

    useEffect(() => {

        fetch_discounts()
        localStorage.setItem('page','other')

    },[])

    return (

        <section className="w-full">

            {store.loading && <GlobalLoader/>}

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <TagIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Codes promos</span>
                </div>
                <div className="">
                    <button onClick={() => dispatch({type : 'TOGGLE_ADD_PROMO_INTERFACE', payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center font-bold">Ajouter +</button>
                </div>
            </header>

            {store.promo.addInterfaceVisible && <CreatePromo/>}

            <ToastContainer/>

            <article className="">

                {
                    store.promo.list.length > 0 ?

                    <div className="grid md:grid-cols-5 gap-5">

                        {store.promo.list.map(discount => <Discount data={discount}/>)}

                    </div>

                    :

                    <div className="">
                        Aucun code promo pour le moment
                    </div>

                }

            </article>

        </section>

    )

}