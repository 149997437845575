

import React from "react"
import { getData , postData} from "../lib/request"
import { SET_PUB_AS_STARRED } from "../resources/routes"


export default class Pub {

    static async starPub(data)
    {

        let response = await postData(data,SET_PUB_AS_STARRED)

        return response

    }

}