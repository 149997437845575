import React from "react"
import { DELETE_CAR_BOOKING, GET_BOOKINGS, GET_CAR_BOOKINGS, VALIDATE_BOOKING } from "../resources/routes"
import { getData , postData} from "../lib/request"


export default class booking {

    static async getBookings ()
    {

        let response = await getData(GET_BOOKINGS)

        return response

    }

    static async validateBooking (id)
    {

        let response = await postData({},VALIDATE_BOOKING + '/' + id)

        return response

    }

    static async getCarBookings()
    {

        let response = await getData(GET_CAR_BOOKINGS)

        return response

    }

    static async deleteCarBookings(data)
    {

        let response = await postData(data,DELETE_CAR_BOOKING)

        return response

    }

}