import React, { useContext, useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import { ACTIVATE_PARTNER_ACCOUNT, CREATE_CAR, LOCOMOTION_CATEGORIES, LOCOMOTION_GENERIC_FEATURES, LOCOMOTION_TYPES } from "../resources/routes";
import TextInput from "./TexInput";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FORM_DATA } from "../resources/types";
import GlobalLoader from "./GlobalLoader";

export default function Activation({user,role,visibility})
{

    const {postData,getData} = useRequest()

    const {visible, setVisible} = useContext(visibility)

    const [_user,setUser] = useState(user)

    const [types,setTypes] = useState([])

    const [generics,setGenerics] = useState([])

    const [layoutGenerics,setLayoutGenerics] = useState([])

    const [locomotionType,setLocomotionType] = useState(null)

    const [categories,setCategories] = useState([])

    const [carCategory,setCarCategory] = useState(null)

    const [glength,setGlength] = useState(1)

    const [color,setColor] = useState(null)

    const [leasable,setLeasable] = useState(null)

    const [dailyPrice,setDailyPrice] = useState(0)

    const [frontPic,setFrontPic] = useState(null)
    const [backPic,setBackPic] = useState(null)
    const [picInsurance,setPicInsurance] = useState(null)
    const [picCarteGrise,setPicCarteGrise] = useState(null)
    const [pic,setPic] = useState(null)

    const [brand,setBrand] = useState(null)
    const [sets,setSets] = useState(null)
    const [year,setYear] = useState(null)
    const [engine,setEngine] = useState(null)
    const [files,setFiles] = useState([])
    const [lid,setLid] = useState(null)

    const [payment,setPayment] = useState(null)
    const [years,setYears] = useState(null)
    const [commission,setCommission] = useState(null)

    const [loading,setLoading] = useState(false)

    const handleTouch = (e) => {

        if(e.target == document.getElementById('activation-body'))
        {
            setVisible(false)
        }

    }

    const handleCarCategoryChange = (e) => {

        setCarCategory(e.target.value)

    }

    const fetch_locomotion_types = async () => {

        let response = await getData(LOCOMOTION_TYPES)

        if(response.status == 200)
        {
            setTypes(response.data.types)
        }
        else{
            // alert(response.message)
        }

    }

    const fetch_locomotion_generic_features = async () => {

        let response = await getData(LOCOMOTION_GENERIC_FEATURES)

        if(response.status == 200)
        {
            // alert(JSON.stringify(response.data))
            setGenerics(response.data.features)
        }
        else{
            // alert(response.message)
        }

    }

    const fetch_locomotion_categories = async () => {

        let response = await getData(LOCOMOTION_CATEGORIES)

        setCategories(response.data.categories)

    }
    
    const handleTypeSelect = (type) => {

        let raw_data = generics

        // alert(raw_data)

        let filtered_raws = generics.filter(generic => generic.locomotion_type_id == type)

        // alert(JSON.stringify(filtered_raws))

        setLayoutGenerics(filtered_raws)

        setLocomotionType(type)

    }

    const handleFileInputChange = async e => {

        setPic(e.target.files[0])

    }

    const handleSubmit = async e => {

        e.preventDefault()

        // if(role == 'conducteur')
        // {

        //     let locomotion_data = {
        //         category : carCategory,
        //         locomotion_type_id : locomotionType,
        //         color : color,
        //         leasable : leasable,
        //         brand : brand,
        //         year : year,
        //         sets : sets,
        //         engine : engine,
        //         daily_price : dailyPrice,
        //         front_pic : frontPic,
        //         back_pic : backPic,
        //         pic_insurance : picInsurance,
        //         pic_carte_grise : picCarteGrise,
        //         files : files,
        //         owner : _user
        //     }

        //     let form = new FormData();

        //     for (let key in locomotion_data) {
        //         if (locomotion_data.hasOwnProperty(key) && key !== 'files') {
        //             form.append(key, locomotion_data[key]);
        //         }
        //     }

        //     files.forEach((file, index) => {
        //         // Assuming each item in 'files' is a File object
        //         // 'files[]' is used to indicate an array of files
        //         form.append(`files[${index}]`, file);
        //     });

        //     // alert(form.get("files[0]"))
            

        //     // Create the locomotion then activate the partner account

        //     const create_locomotion = async (data,route) => {

        //         setLoading(true)

        //         let response = await postData(data,route,FORM_DATA)

        //         alert(JSON.stringify(response))

        //         if(response.status == 200)
        //         {
        //             return response.data.locomotion.id
        //         }
        //         else{
        //             return false
        //         }

        //     }
    
        //     await create_locomotion(form,CREATE_CAR)
        //     .then( async res => {

        //         if(res != false)
        //         {

        //             var data = {
        //                 driver : _user,
        //                 token : localStorage.getItem('access_token'),
        //                 owner : _user,
        //                 locomotion_id: res,
        //                 user_id: _user
        //             }

        //             let response = await postData(data,ACTIVATE_PARTNER_ACCOUNT)
            
        //             // alert(JSON.stringify(response))
            
        //             if(response.status == 400)
        //             {
        //                 toast.error('Something went wrong')
        //                 setVisible(false)
        //             }
        //             else{
        //                 toast.success(response.message)
        //                 setVisible(false)
        //             }

        //             setLoading(false)

        //         }
        //         else{
        //             alert('Something went wrong')
        //             setLoading(false)
        //         }

        //     })

        // }
        // else{

            

        // }

        var data = {
            user_id : _user,
            token : localStorage.getItem('access_token'),
            car_payment : payment,
            on_errand_commission : commission,
            years : years
        }

        let response = await postData(data,ACTIVATE_PARTNER_ACCOUNT)

        // alert(JSON.stringify(response))

        if(response.status == 400)
        {
            toast.error('Something went wrong')
            setVisible(false)
        }
        else{
            toast.success('Partenaire activé avec succès')
            // setTimeout(() => window.location.reload(), 1500);
            setVisible(false)
        }

    }

    useEffect(() => {

        fetch_locomotion_types()

        fetch_locomotion_generic_features()

        fetch_locomotion_categories()

    },[])

    return (

        <section onClick={handleTouch} className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center" id="activation-body">

            <article className="lg:w-7/12 bg-white p-4 overflow-y-auto h-[400px]">

                <form onSubmit={handleSubmit} name="validationForm">

                {loading && <GlobalLoader/>}

                <ToastContainer/>

                    <header className="font-bold text-lg">Identité du partenaire</header>

                    {/* {
                        role == 'conducteur' &&
                        <div>
        
                            <div className="pb-4">
                                <label className="block py-4">Type d'engin</label>
                                <select name="" onChange={(e) => handleTypeSelect(e.target.value)} className="bg-[#e6e6e6] p-3 w-full focus:outline-none">
                                    <option>Type d'engin</option>
                                    {
                                        types.map(type => (
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Catégorie</label>
                                <select name="" onChange={handleCarCategoryChange} className="bg-[#e6e6e6] p-3 w-full focus:outline-none">
                                    <option>Catégorie</option>
                                    {
                                        categories.map(category => (
                                            <option key={category.id} value={category.id}>{category.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Image avant</label>
                                <input type="file" name="front_pic" onChange={e => setFrontPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Votre mot de passe"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Image arrière</label>
                                <input type="file" name="back_pic" onChange={e => setBackPic(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Votre mot de passe"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Image assurance</label>
                                <input type="file" name="pic_insurance" onChange={e => setPicInsurance(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Votre mot de passe"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Image carte grise</label>
                                <input type="file" name="pic_carte_grise" onChange={e => setPicCarteGrise(e.target.files[0])} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Votre mot de passe"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Couleur</label>
                                <input type="text" onInput={(e) => setColor(e.target.value)} name="color" className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Couleur du véhicule"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Nombre de places</label>
                                <input type="number" autoComplete="off" onInput={(e) => setSets(e.target.value)} name="color" className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Couleur du véhicule"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Année</label>
                                <input type="number" autoComplete="off" onInput={(e) => setYear(e.target.value)} name="color" className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Couleur du véhicule"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">Marque</label>
                                <input type="text" onInput={(e) => setBrand(e.target.value)} name="color" className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Couleur du véhicule"/>
                            </div>
                            <div className="pb-4">
                                <label className="block py-4">
                                    <span>Gallerie</span>
                                    <button onClick={(e) => {
                                        e.preventDefault()
                                        setGlength(glength+1)
                                    }} className="bg-gray-700 text-white font-bold w-6 h-6 mx-4">+</button>
                                </label>
                                {Array.from({ length: glength }, (_, i) => (
                                <input
                                    key={i}
                                    type="file"
                                    name={`gallery[]`}
                                    className="bg-[#e6e6e6] p-3 w-full focus:outline-none my-2"
                                    placeholder="Couleur du véhicule"
                                    onChange={e => setFiles(prevFiles => [...prevFiles, e.target.files[0]])}
                                />
                                ))}
                            </div>
                            <div className="pb-4">
                                <input type="checkbox" id="daily_price" onInput={(e) => setLeasable(leasable ? false : true)} name="leasable" className=""  placeholder="Couleur du véhicule"/>
                                <label className="px-3" htmlFor="daily_price">Disponible à la location</label>
                            </div>
                            {
                                leasable && 
                                <div className="pb-4">
                                    <label className="block py-4">Prix journalier</label>
                                    <input type="number" required onInput={(e) => setDailyPrice(e.target.value)} name="color" className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder="Prix journalier"/>
                                </div>
                            }
                        </div>
                    } */}

                    <div className="">
                        <label className="block py-4">Montant total a payer <span className="font-bold text-xs text-red-700">(Uniquement pour la gestion de vehicules)</span></label>
                        <input type="number" name="car_payment" onChange={e => setPayment(e.target.value)} className="bg-[#dbdbdb] p-3 w-full focus:outline-none"  placeholder="Montant total a payer"/>
                    </div>
                    <div className="">
                        <label className="block py-4">Nombre d'annees de remboursement <span className="font-bold text-xs text-red-700">(Uniquement pour la gestion de vehicules)</span></label>
                        <input type="number" name="years" onChange={e => setYears(e.target.value)} className="bg-[#dbdbdb] p-3 w-full focus:outline-none"  placeholder="Nombre d'annees de remboursement"/>
                    </div>
                    <div className="">
                        <label className="block py-4">Pourcentage sur chaque course <span className="font-bold text-xs text-red-700">(Uniquement pour la gestion de vehicules)</span></label>
                        <input type="number" name="on_errand_commission" onChange={e => setCommission(e.target.value)} className="bg-[#dbdbdb] p-3 w-full focus:outline-none"  placeholder="Pourcentage sur chaque course"/>
                    </div>

                    <div className="mt-4">
                        <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Valider</button>
                    </div>
                    
                </form>

            </article>

        </section>

    )

}

// const convert_to_base64 = async (file) => {

    //       return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
        
    //         reader.onloadend = () => {
    //           // The result will be the base64-encoded string
    //           resolve(reader.result);
    //         };
        
    //         reader.onerror = () => {
    //           reject(new Error('Error reading file as base64'));
    //         };
        
    //         // Read the file as a data URL (base64)
    //         reader.readAsDataURL(file);
    //       });

    // }