import { getData, postData } from "../lib/request";
import { ACTIVATE_FURNISHED_FLAT, ACTIVATE_GUESTHOUSE, ACTIVATE_RENTAL_PROPERTY, ACTIVATE_SELLABLE, BULKY_DELETE_HOUSES, BULKY_TOGGLE_HOUSES, DELETE_BULK_SELLABLES, DELETE_BUYER_INTERESTED, GET_BULK_SELLABLES, GET_BULKY_HOUSES, GET_BUYER_INTERESTED, GET_MANAGED_HOUSES, GET_TAXABLE_HOUSES, GET_UNACTIVATED_FLATS, GET_UNACTIVATED_GUESTHOUSE, GET_UNACTIVATED_RENTAL_PROPERTIES, GET_UNACTIVATED_SELLABLES, PAY_TAXE, TOGGLE_BULK_SELLABLE } from "../resources/routes";


export default class house {

    static async getHouses()
    {

        let response = await getData(GET_TAXABLE_HOUSES)

        return response

    }

    static async payTaxe(data)
    {

        let response = await postData({},PAY_TAXE + '/' + data.house_id)

        return response

    }

    static async getUnactivatedFurnishedFlats()
    {

        let response = await getData(GET_UNACTIVATED_FLATS)

        return response

    }

    static async activateFlat(data)
    {

        let response = await postData(data,ACTIVATE_FURNISHED_FLAT)

        return response

    }

    static async getUnactivatedGuesthouses()
    {

        let response = await getData(GET_UNACTIVATED_GUESTHOUSE)

        return response

    }

    static async activateGuesthouse(data)
    {

        let response = await postData(data,ACTIVATE_GUESTHOUSE)

        return response

    }

    // 

    static async getUnactivatedSellables()
    {

        let response = await getData(GET_UNACTIVATED_SELLABLES)

        return response

    }

    static async activateSellable(data)
    {

        let response = await postData(data,ACTIVATE_SELLABLE + `/${data.type}`)

        return response

    }

    static async getInterestedUsers()
    {

        let response = await getData(GET_BUYER_INTERESTED)

        return response

    }

    static async handleRequest(data)
    {

        let response = await postData(data,DELETE_BUYER_INTERESTED + `/${data.type}`)

        return response

    }

    // Immo management

    static async getManagedHouses()
    {

        let response = await getData(GET_MANAGED_HOUSES)

        return response

    }

    // Sellables management

    static async getBulkSellables()
    {

        let response = await getData(GET_BULK_SELLABLES)

        return response

    }

    static async toggleBulkSellable(data)
    {

        let response = await postData(data,TOGGLE_BULK_SELLABLE)

        return response

    }

    static async deleteBulkSellable(data)
    {

        let response = await postData(data,DELETE_BULK_SELLABLES)

        return response

    }

    // Bulky houses

    static async getBulkyHouses()
    {

        let response = await getData(GET_BULKY_HOUSES)

        return response

    }

    static async bulkyToggleHouses(data)
    {

        let response = await postData(data,BULKY_TOGGLE_HOUSES)

        return response

    }

    static async bulkyDeleteHouses(data)
    {

        let response = await postData(data,BULKY_DELETE_HOUSES)

        return response

    }

}