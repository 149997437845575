import React, { useContext, useEffect } from "react";

import {
    NewspaperIcon,
    ArrowPathIcon,
    MapIcon,
    ArrowRightCircleIcon,
    CheckBadgeIcon,
    TrashIcon,
    EyeIcon
  } from '@heroicons/react/24/outline'

  import { AppStore } from "../../App"
import errands from "../../services/errands";
import GlobalLoader from "../../components/GlobalLoader";
import Pagination from "../../components/Errands/Pagination";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Errands({ctx_memo})
{

    const {store,dispatch} = useContext(AppStore)

    const navigate = useNavigate()

    const remakeData = (reference,n) => {
        const newData = reference.reduce((acc, curr, index) => {
          const chunkIndex = Math.floor(index / n);
          if (!acc[chunkIndex]) {
            acc[chunkIndex] = [];
          }
          acc[chunkIndex].push(curr);
          return acc;
        }, []);
        return newData
    };

    const singleView = (e,data) => {

        e.preventDefault()

        dispatch({type : 'SET_SINGLE_VIEW', payload : data})

        // Navigate to the single view route

        navigate(`/courses/${data.id}`)

    }

    const fetch_errands = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        errands.getErrands().then(response => {

            if(response.status == 200)
            {
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                dispatch({type : 'SAVE_ERRANDS',payload : remakeData(response.data.errands,7)})
            }
            else{
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                alert('Something gone wrong')
            }

        })

    }

    const dropErrands = async () => {

        if(window.confirm('Voulez-vous vraiment supprimer toutes les courses ?'))
        {
            
            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            errands.dropErrands().then(response => {
    
                if(response.status == 200)
                {
                    toast.success('Toutes les courses sont maintenant supprimées')
                    fetch_errands()
                }
                else{
                   
                    alert('Something gone wrong')
                }
    
            })
    
            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    const dropErrand = async id => {

        if(window.confirm('Voulez-vous vraiment supprimer cette course ?'))
        {
            
            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            errands.dropErrand(id).
            then(async response => {
    
                if(response.status == 200)
                {
                    toast.success('Cette course est maintenant supprimée')
                    await fetch_errands()
                }
                else{
                   
                    alert('Something gone wrong')
                }
                
            })
    
            dispatch({type : 'TOGGLE_APP_LOADING', payload : false})

        }

    }

    useEffect(() => {

        fetch_errands()
        localStorage.setItem('page','other')

    },[])

    return (

        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg flex items-center justify-between">
                <div className="">
                    <MapIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Historique des courses</span>
                </div>
                <div className="">
                    <button onClick={e => dropErrands(e)} className="bg-blue-700 text-white p-3 text-sm">
                        Supprimer les courses
                    </button>
                </div>
            </header>

            {store.loading && <GlobalLoader/>}

            <ToastContainer/>
            
            <article className='mt-10'>

                <header className='text-lg font-bold'>
                    Historique des courses
                </header>

                <div className=''>

                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">#</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Conducteur</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Montant</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            store.errands.list[store.errands.listIndex]?.length > 0 ? 

                            store.errands.list[store.errands.listIndex].map(errand => (
                                <tr key={errand.id} className={`${errand?.thedriver == null && "bg-red-200"}`}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {errand.id}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.thedriver?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.theclient?.c_name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.infos[0]?.total_price} {errand.infos[0]?.total_price && <span className="font-bold text-sm">FCFA</span>}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{errand.is_cav ? 'Volee' : 'Normal'}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                        
                                        {
                                            errand.thedriver != null && 
                                            <a onClick={e => singleView(e,errand)} href="" className="text-white bg-blue-600 p-2 rounded-md flex items-center gap-2">
                                                <EyeIcon className='w-6'/>
                                                <span>Voir</span>
                                            </a>
                                        }

                                        <button onClick={e => dropErrand(errand.id)} href="" className="text-white hover:text-white bg-red-600 p-2 rounded-md flex items-center gap-2">
                                            <TrashIcon className='w-4'/>
                                            <span>Supprimer</span>
                                        </button>

                                    </td>
                                </tr>
                            ))

                            :

                            <tr className=''>Aucune course</tr>

                        }
                        </tbody>
                    </table>

                    <div className="flex items-center gap-10 mt-5">

                        <header className="font-bold">Pagination</header>

                        <Pagination data={store.errands.list}/>

                    </div>

                </div>

            </article>
            

        </section>

    )

}