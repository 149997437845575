import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from '../../components/GlobalLoader';
import { useEffect, useRef, useState } from 'react';
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Cog8ToothIcon } from '@heroicons/react/16/solid';
import { userService } from '../../services/user.service';

export default function AdminMan()
{

    const [loading,setLoading] = useState(false)

    const [admins,setAdmins] = useState([])

    const [toggleUpdate,setToggleUpdate] = useState(false)

    const [targetedAdmin,setTargetedAdmin] = useState(null)

    const callExecuted = useRef(false)

    const [data,setData] = useState({
        c_name : null,
        email : null,
        phone : null,
        password : null,
        role : null
    })

    const fetch_admins = async () => {

        setLoading(true)

        let response = await userService.getAdmins()

        if(response)
        {

            if(response.status == 200)
            {

                setAdmins(response.data.admins)

            }

        }

        setLoading(false)

    }

    const save_admin = async e => {

        e.preventDefault()

        let _data = data

        if(window.confirm('Voulez vous vraiment ajouter cet adminstrateur ?'))
        {

            setLoading(true)

            let response = await userService.addAdmin(data)

            if(response)
            {

                if(response.status == 200)
                {

                    toast.success('Administrateur ajoute avec succes')
                    await fetch_admins()
                    setToggleUpdate(false)

                }
                else{
                    toast.error('Something went wrong')
                }

            }

            setLoading(false)

        }

    }

    const update_admin = async e => {

        e.preventDefault()

        let _data = targetedAdmin

        console.log(_data)

        if(window.confirm('Voulez vous vraiment mettre a jour cet adminstrateur ?'))
        {

            setLoading(true)

            let response = await userService.updateAdmin(_data)

            if(response)
            {

                if(response.status == 200)
                {

                    toast.success('Administrateur ajoute avec succes')
                    await fetch_admins()
                    setToggleUpdate(false)

                }
                else{
                    toast.error('Something went wrong')
                }

            }

            setLoading(false)

        }

    }

    const delete_admin = async (e,target) => {

        e.preventDefault()

        let _data = {
            id : target
        }

        if(window.confirm('Voulez vous vraiment supprimer cet administrateur ?'))
        {

            setLoading(true)

            let response = await userService.deleteAdmin(_data)

            if(response)
            {

                if(response.status == 200)
                {

                    toast.success('Administrateur supprime avec succes')
                    await fetch_admins()

                }
                else{
                    toast.error('Something went wrong')
                }

            }

            setLoading(false)

        }

    }

    const trigger_user_update = (e,admin) => {

        e.preventDefault()

        setToggleUpdate(true)
        // console.log(admin)
        setTargetedAdmin({
            c_name : admin.c_name,
            phone : admin.phone,
            email : admin.email,
            role : admin.role,
            password : null,
            id : admin.id
        })

    }

    const matchRoles = role => {

        if(role == 'admin_immo')
        {
            return 'Gestionnaire immobilier'
        }
        if(role == 'admin_transport')
        {
            return 'Gestionnaire transport'
        }

    }

    useEffect(() => {

        if(callExecuted.current == false)
        {

            fetch_admins()

            callExecuted.current = true

        }

    },[])

    return (

        <section className='w-full'>

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                <div className="">
                    <Cog8ToothIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Gestion des administrateurs</span>
                </div>
                <div className=''>
                    <button onClick={e => setToggleUpdate(true)} className='bg-blue-700 text-white font-bold flex items-center gap-2 p-3 rounded text-sm'>
                        <PlusCircleIcon className='w-6'/>
                        <span className=''>Ajouter un administrateur</span>
                    </button>
                </div>
            </header>

            {loading && <GlobalLoader/>}

            <ToastContainer/>

            {
                admins.length == 0 ?

                <div>Aucun administrateur existant</div> : 

                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        #
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Nom
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Email
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        No Tel
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Role
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Action
                        </th>
                    </tr>
                    </thead> 
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {admins.map((admin,index) => (
                        <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {index + 1}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                {admin?.c_name}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                {admin?.email}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                {admin?.phone}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                <span className='p-2 rounded bg-green-700 text-white font-bold text-sm'>{admin?.role == 1 ? 'Administrateur principal' : matchRoles(admin?.role)}</span>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                <a onClick={e => trigger_user_update(e,admin)} className="bg-blue-600 hover:bg-blue-900 cursor-pointer text-white p-2 rounded flex items-center gap-1">
                                    <PencilIcon className="w-4"/>
                                    <span className="">Modifier</span>
                                </a>
                                <a onClick={e => delete_admin(e,admin?.id)}  className="bg-red-600 hover:bg-red-900 text-white rounded-md p-2 cursor-pointer flex items-center gap-1">
                                    <TrashIcon className="w-4"/>
                                </a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            }

            {
                toggleUpdate && targetedAdmin == null ? 
                <section onClick={e => {

                    setTargetedAdmin(null)
                    setToggleUpdate(false)
                    
                }} className='fixed bg-black/50 inset-0 flex items-center justify-center z-30'>

                    <article onClick={e => e.stopPropagation()} className='bg-white w-7/12 h-[600px] overflow-y-auto p-10'>

                        <form className='' onSubmit={e => save_admin(e)}>

                            <div className="pb-4">
                                <label className="block py-4">Choisir le role</label>
                                <select required name="" onChange={e => setData({...data,role : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none">
                                    <option></option>
                                    <option value='admin'>Administrateur</option>
                                    <option value='admin_immo'>Gestionnaire immobilier</option>
                                    <option value='admin_transport'>Gestionnaire transport et VTC</option>
                                </select>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Nom de l'utilisateur <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" name="link" onChange={e => setData({...data,c_name : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Nom de l'utilisateur`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Email de l'utilisateur <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" name="link" onChange={e => setData({...data,email : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Email de l'utilisateur`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Numero de telephone <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" name="link" onChange={e => setData({...data,phone : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Numero de telephone`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Mot de passe <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="password" name="link" onChange={e => setData({...data,password : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Mot de passe`}/>
                            </div>

                            <div>
                                <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Ajouter</button>
                            </div>

                        </form>

                    </article>

                </section>
                :
                ''
            }

            {
                toggleUpdate && targetedAdmin != null ? 
                <section onClick={e => {

                    setTargetedAdmin(null)
                    setToggleUpdate(false)
                    
                }} className='fixed bg-black/50 inset-0 flex items-center justify-center z-30'>

                    <article onClick={e => e.stopPropagation()} className='bg-white w-7/12 h-[600px] overflow-y-auto p-10'>

                        <form className='' onSubmit={e => update_admin(e)}>

                            <div className="pb-4">
                                <label className="block py-4">Nom de l'utilisateur <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" value={targetedAdmin.c_name} name="link" onChange={e => setTargetedAdmin({...targetedAdmin,c_name : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Nom de l'utilisateur`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Email de l'utilisateur <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" value={targetedAdmin.email} name="link" onChange={e => setTargetedAdmin({...targetedAdmin,email : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Email de l'utilisateur`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Numero de telephone <span className="text-xs font-bold text-red-600">(Requis)</span></label>
                                <input type="text" value={targetedAdmin.phone} name="link" onChange={e => setTargetedAdmin({...targetedAdmin,phone : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Numero de telephone`}/>
                            </div>

                            <div className="pb-4">
                                <label className="block py-4">Mot de passe <span className="text-xs font-bold text-red-600">(Facultatif, sauf si vous desirez changer le mot de passe)</span></label>
                                <input type="password" name="link" onChange={e => setTargetedAdmin({...targetedAdmin,password : e.target.value})} className="bg-[#e6e6e6] p-3 w-full focus:outline-none"  placeholder={`Mot de passe`}/>
                            </div>

                            <div>
                                <button type="submit" className="bg-[#2e7ba5] p-3 pr-10 text-white">Mettre a jour</button>
                            </div>

                        </form>

                    </article>

                </section>
                :
                ''
            }

        </section>

    )

}