import { useState } from "react"
import HouseActivation from "../../components/HouseActivation"
import HouseManagement from "../../components/HouseManagement"


export default function Houses()
{

    const [toggleActivation,setToggleActivation] = useState(true)

    const [toggleManagement,setToggleManagement] = useState(false)

    const handleClick = (type) => {

        if(type == 'activation')
        {
            setToggleActivation(true)
            setToggleManagement(false)
        }
        else if(type == 'management')
        {
            setToggleActivation(false)
            setToggleManagement(true)
        }
    }

    

    return (

        <section className="w-full">

            <article className="flex gap-3 my-10">

                <button onClick={e => handleClick('activation')} className={`p-3 rounded font-bold ${toggleActivation ? "bg-blue-600 text-white" : "bg-gray-400 text-black"}`}>
                    Activation
                </button>

                <button onClick={e => handleClick('management')} className={`p-3 rounded font-bold ${toggleManagement ? "bg-blue-600 text-white" : "bg-gray-400 text-black"}`}>
                    Gestion
                </button>

            </article>

            {
                toggleActivation && <HouseActivation/>
            }

            {
                toggleManagement && <HouseManagement/>
            }
            

        </section>

    )

}