import React, { createContext, useEffect, useMemo, useState } from "react";
import useRequest from "../hooks/useRequest";
import Navigator from "../components/navigator";
import Login from "../pages/login";
import { LOGIN } from "../resources/routes";

import Connectivity from "../components/Connectivity";

export const AuthContext = createContext({
    login : () => {},
    logout: () => {},
    setLogin : () => {}
})

export default function AuthPorvider(){

    const {postData,getData} = useRequest()

    const [isLogin,setIsLoggedIn] = useState()

    const [connected,setConnected] = useState(true)

    const setLogin = (data,status) => {

        // Some action here when the use is not logged in

        if(status ===  true)
        {

            sessionStorage.setItem('access_token',data.access_token)
            sessionStorage.setItem('user',JSON.stringify(data.user))
            sessionStorage.setItem('user_role',JSON.stringify(data.user.role))
            sessionStorage.setItem('login',true)

        }
        else{

            sessionStorage.clear('access_token')
            sessionStorage.clear('user')
            sessionStorage.clear('user_role')
            sessionStorage.clear('login',false)

        }

        setIsLoggedIn(status)

    }

    const login = async (username,password) => {

        // 

        let data = {
            phone:username,
            password:password
        }

        return await postData(data,LOGIN);

    }

    const logout = () => {

        setLogin({},false)

    }

    const stMemory = useMemo(() => ({login:login,logout:logout,setLogin:setLogin}))

    useEffect(() => {

        const resetLogin = () => {

            if(sessionStorage.getItem('login'))
            {
                let data = {
                    access_token : sessionStorage.getItem('access_token'),
                    user : JSON.parse(sessionStorage.getItem('user'))
                }
                setLogin(data,true)
            }

        }

        resetLogin()

        const handleOnline = () => {
            setConnected(true);
        };
      
        const handleOffline = () => {
            setConnected(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // return () => {
        //     window.removeEventListener('online', handleOnline);
        //     window.removeEventListener('offline', handleOffline);
        // }

    },[])

    return (

        <React.Fragment>
            {
                !connected ? 
                <Connectivity/> : 
                <React.Fragment>
                
                    {
                        isLogin ?

                        <Navigator ctx_memo={stMemory}/> 
                        
                        : 

                        <AuthContext.Provider value={stMemory}>
                            <Login auth_tool={AuthContext}/>
                        </AuthContext.Provider>

                    }
                        
                </React.Fragment>
            }
        </React.Fragment>

    )

}